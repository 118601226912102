<label class="switch" [tooltip]="toggleTooltip ? template : ''" triggers="hover">
	<input type="checkbox" [id]="id" [checked]="check" [name]="name" (click)="toggle()" />
	<span class="slider round">{{ label }}</span>
</label>

<ng-template #template>
	<div class="tooltip-inner tooltip-internal-div" [ngClass]="check ? 'tooltip-checked' : 'tooltip-unchecked'">
		{{ toggleTooltip }}
	</div>
</ng-template>
