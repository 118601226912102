import * as pagesConfig from './../../../config/pages-config';
import { Router } from '@angular/router';
import { AuthenticateService } from './authenticate.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(private authenticateService: AuthenticateService, private router: Router) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.authenticateService.isAuthenticated()) {
			// FShould Redirect To Login Page
			this.router.navigate([pagesConfig.config.login.name], {
				queryParams: {
					targetUrl: state?.url,
				},
			});
			return false;
		} else {
			return true;
		}

		// // Check Access Token Validity
		// if (this.jWTHelper.isAccessTokenExpired()) {

		//   if (this.jWTHelper.isRefereshTokenExpired()) {
		//     // FShould Redirect To Login Page
		//     this.router.navigate([pagesConfig.config.login.name], {
		//       queryParams: {
		//         targetUrl: state.url
		//       }
		//     });
		//     return false;
		//   } else { // refresh token not Expired
		//     return this.authenticateService.refreshTokens().map((response: Response) => {
		//       return true;
		//     });
		//   }
		// }
		// // Access Token Valid
		// return true;
	}
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.canActivate(childRoute, state);
	}
}
