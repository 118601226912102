import { ChatConfigNewItemComponent } from './components/chat-config-new-item/chat-config-new-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpFooterComponent } from './components/sp-footer/sp-footer.component';
import { SpHeaderComponent } from './components/sp-header/sp-header.component';
import { SpTextComponent } from './components/sp-text/sp-text.component';
import { SpToggleButtonComponent } from './components/sp-toggle-button/sp-toggle-button.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SpIconAutoCompleteComponent } from './components/sp-icon-auto-complete/icon-auto-complete.component';
import { SidemenuNewItemComponent } from './components/sidemenu-new-item/sidemenu-new-item.component';
import { CheckBoxDropDownComponent } from './components/sp-check-box-drop-down/check-box-drop-down.component';
import { SpDropdownComponent } from './components/sp-dropdown/sp-dropdown.component';
import { SpCheckBoxComponent } from './components/sp-check-box/sp-check-box.component';
import { AngularMultiSelect } from './components/multi-select-dropdown/multiselect.component';
import { ListFilterPipe } from './components/multi-select-dropdown/list-filter';
import { ClickOutsideDirective } from './components/multi-select-dropdown/clickOutside';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from './pipes/filter.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		TabsModule.forRoot(),
		ModalModule,
		TranslateModule,
		TooltipModule.forRoot(),
	],
	declarations: [
		SpToggleButtonComponent,
		SpFooterComponent,
		SpHeaderComponent,
		SpTextComponent,
		SidemenuNewItemComponent,
		CheckBoxDropDownComponent,
		SpIconAutoCompleteComponent,
		SpDropdownComponent,
		SpCheckBoxComponent,
		AngularMultiSelect,
		ListFilterPipe,
		ClickOutsideDirective,
		FilterPipe,
		SearchPipe,
		ChatConfigNewItemComponent,
	],
	providers: [],
	exports: [
		SpFooterComponent,
		SpHeaderComponent,
		SpTextComponent,
		SpToggleButtonComponent,
		SidemenuNewItemComponent,
		CheckBoxDropDownComponent,
		SpIconAutoCompleteComponent,
		SpDropdownComponent,
		SpCheckBoxComponent,
		AngularMultiSelect,
		ListFilterPipe,
		ClickOutsideDirective,
		SearchPipe,
		FilterPipe,
		ChatConfigNewItemComponent,
	],
})
export class SharedModule {}
