<div class="form-group col-12 sp-text">
	<label for="{{ id }}" *ngIf="label" class="my-label">{{ label }}</label>
	<input
		*ngIf="!isDateInput"
		type="{{ type }}"
		class="form-control"
		name=""
		id="{{ id }}"
		[disabled]="disabled"
		[pattern]="pattern"
		[required]="isRequired"
		[placeholder]="placeholder"
		(keyup)="Change()"
		(blur)="onBlur()"
		[(ngModel)]="innerValue"
		[disabled]="disable"
		#variable="ngModel"
	/>
	<input
		*ngIf="isDateInput"
		type="date"
		class="form-control"
		value="{{ inputValue }}"
		name=""
		id="{{ id }}"
		[disabled]="disabled"
		[required]="isRequired"
		(keyup)="Change()"
		(blur)="onBlur()"
		[(ngModel)]="innerValue"
	/>
	<a class="icon" [ngClass]="{ disabled: disabled }">
		<img *ngIf="showCopyIcon" (click)="copyClick()" class="icon-i" src="/assets/copy.png" alt="" />
		<img
			*ngIf="showPasswordIcon && isPassword && hashed"
			(click)="toggleIcon()"
			class="icon-i"
			src="/assets/images/icon-eye.png"
			alt=""
		/>
		<img
			*ngIf="showPasswordIcon && isPassword && !hashed"
			(click)="toggleIcon()"
			class="icon-i"
			src="/assets/images/icon-eye.png"
			alt=""
		/>
		<img *ngIf="showInfoIcon && !isPassword" class="icon-i" src="/assets/images/icon-info.png" alt="" />
	</a>
</div>
