import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '../../common/services/configuration.service';
import { Notification } from '../../models/notification.model';
import { NotificationService } from '../../common/services/notification.service';

@Component({
	selector: 'sp-update-eventconfigurations',
	templateUrl: './update-eventconfigurations.component.html',
	styleUrls: ['./update-eventconfigurations.component.scss'],
})
export class UpdateEventconfigurationsComponent implements OnInit {
	constructor(
		private configService: ConfigurationService,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {}
	form: FormGroup;
	configurations: any;
	ngOnInit() {
		this.configurations = this.configService.getEventConfigurations();
		this.form = new FormGroup({
			urlshortenerAuditBootstrapConfig: new FormControl(this.getPropertyValue('auditProperties.bootstrapConfig')),
			urlshortenerAuditTopic: new FormControl(this.getPropertyValue('auditProperties.topic')),
			urlshortenerConsumerBootstrapConfig: new FormControl(this.getPropertyValue('consumerProperties.bootstrapConfig')),
			urlshortenerConsumerGroupID: new FormControl(this.getPropertyValue('consumerProperties.groupId')),
			urlshortenerConsumerIdleBetweenPolls: new FormControl(
				this.getPropertyValue('consumerProperties.idleBetweenPolls')
			),
			urlshortenerConsumerMaxPollRecordsConfig: new FormControl(
				this.getPropertyValue('consumerProperties.maxPollRecordsConfig')
			),
			urlshortenerConsumerTopic: new FormControl(this.getPropertyValue('consumerProperties.topic')),
			urlshortenerProducerBootstrapConfig: new FormControl(this.getPropertyValue('producerProperties.bootstrapConfig')),
			urlshortenerProducerTopic: new FormControl(this.getPropertyValue('producerProperties.topic')),
		});
	}

	private getPropertyValue(propertyPath: string): any {
		if (this.configurations == null || this.configurations.config == null) {
			return null;
		}
		const config = this.configurations.config['url-shortener-ts'];
		return config ? this.getNestedProperty(config, propertyPath) : null;
	}

	private getNestedProperty(obj: any, propertyPath: string): any {
		return propertyPath.split('.').reduce((o, p) => o && o[p], obj);
	}

	submit() {
		const eventConfigurations = {
			config: {
				'url-shortener-ts': {
					auditProperties: {
						bootstrapConfig: this.form.controls['urlshortenerAuditBootstrapConfig'].value,
						layer: null,
						topic: this.form.controls['urlshortenerAuditTopic'].value,
					},
					consumerProperties: {
						bootstrapConfig: this.form.controls['urlshortenerConsumerBootstrapConfig'].value,
						groupId: this.form.controls['urlshortenerConsumerGroupID'].value,
						idleBetweenPolls: this.form.controls['urlshortenerConsumerIdleBetweenPolls'].value,
						maxPollRecordsConfig: this.form.controls['urlshortenerConsumerMaxPollRecordsConfig'].value,
						topic: this.form.controls['urlshortenerConsumerTopic'].value,
					},
					producerProperties: {
						bootstrapConfig: this.form.controls['urlshortenerProducerBootstrapConfig'].value,
						topic: this.form.controls['urlshortenerProducerTopic'].value,
					},
				},
			},
		};
		const patchObject = {
			EventConfigurations: eventConfigurations,
		};
		this.configService.patchConfiguration(patchObject).subscribe(
			(data) => {
				this.translate.get('home.bonita-config.form.messages').subscribe((data) => {
					const notification = new Notification();
					notification.bodyTitle = data['success-msg'];
					notification.primaryButtonText = data['close-btn'];
					notification.primaryButton = true;
					notification.primaryButtonClick = () => this.notificationService.notificationModal.hide();
					this.notificationService.createNotification(notification);
				});
			},
			(error) => {
				this.translate.get('home.bonita-config.form.messages').subscribe((data) => {
					const notification = new Notification();
					notification.bodyTitle = data['fail-msg'];
					notification.primaryButtonText = data['close-btn'];
					notification.primaryButton = true;
					notification.primaryButtonClick = () => this.notificationService.notificationModal.hide();
					this.notificationService.createNotification(notification);
				});
			}
		);
	}
}
