<div class="form-group col-12 dropdown-form">
	<div class="dropdown">
		<label *ngIf="label" for="{{ id }}"
			>{{ label }}
			<span [ngClass]="{ required: required }" *ngIf="required" style="color: red">*</span>
		</label>
		<select
			#select
			[ngClass]="arrow"
			(blur)="onBlur()"
			(click)="toggel()"
			[disabled]="isDisabled"
			class="{{ isDisabled ? 'disabled-ddl' : '' }} selectpicker form-control"
			name="{{ name }}"
			[(ngModel)]="selected"
		>
			<option style="display: none" value="" *ngIf="defaultOptionText">{{ defaultOptionText }}</option>

			<option value="{{ item.value }}" *ngFor="let item of items">
				{{ item.text }}
			</option>
		</select>
	</div>
</div>
