export const config = {
	login: {
		name: 'login',
		route: '/login',
		//'loadChildren':'../app/common/components/sp-login/sp-login.module#SpLoginModule'
	},
	home: {
		name: 'home',
		route: '/home',
		// 'loadChildren': () => import('src/app/home/home.module').then(m => m.HomeModule)
	},
	forceupdate: {
		name: 'force-update',
		route: '/force-update',
		// 'loadChildren': () => import('../app/force-update/force-update.module').then(m => m.ForceUpdateModule)
	},
	generalconfigbusiness: {
		name: 'general-config-business',
		route: '/general-config-business',
	},
	generalconfigao: {
		name: 'general-config-ao',
		route: '/general-config-ao',
	},
	flowsconfigao: {
		name: 'flows-config-ao',
		route: '/flows-config-ao',
	},
	menuconfig: {
		name: 'menu-config',
		route: '/menu-config',
	},
	urlconfig: {
		name: 'url-config',
		route: '/url-config',
	},
	beautifyLinkconfig: {
		name: 'beautify-link-config',
		route: '/beautify-link-config',
	},
	gdprPropertiesconfig: {
		name: 'gdpr-properties-config',
		route: '/gdpr-properties-config',
	},
	chatBubbleConfig: {
		name: 'chat-config',
		route: '/chat-config',
	},
	bonitaConfig: {
		name: 'bonita-config',
		route: '/bonita-config',
	},
	schamanConfig: {
		name: 'schaman-config',
		route: '/schaman-config',
	},
	userPermissions: {
		name: 'user-permissions',
		route: '/user-permissions',
	},
	latestBuilds: {
		name: 'latest-builds',
		route: '/latest-builds',
	},
	paymentCSV: {
		name: 'payment-csv',
		route: '/payment-csv',
	},
	walletInfo: {
		name: 'wallet-info-csv',
		route: '/wallet-info-csv',
	},
	pciVersionConfig: {
		name: 'pciVersionConfig',
		route: '/pciVersionConfig',
	},
	TopUpDiscrimination: {
		name: 'top-up-decsrimination',
		route: 'top-up-decsrimination-config',
	},
	AddNewPAgeDeepLink: {
		name: 'add-new-page-deeplink',
		route: '/add-new-page-deeplink',
	},
	UpdateEventConfigurations: {
		name: 'update-eventconfigurations',
		route: '/update-eventconfigurations',
	},
	productConfig: {
		name: 'product',
		route: '/product-config',
	},
	ChatModulePageConfig: {
		name: 'chat-module-page-config',
		route: '/chat-module-page-config',
	},
	groupsList: [
		{ text: 'Account', value: 'Account' },
		{ text: 'Service', value: 'Service' },
		{ text: 'General', value: 'General' },
	],
	directToList: [
		{ text: 'External', value: 'External' },
		{ text: 'Internal', value: 'Internal' },
		{ text: 'Contingency page', value: 'Contingency' },
		{ text: 'Old portal', value: 'OldPortal' },
		{ text: 'E-Care', value: 'ECare' },
	],
	internalRoutesList: [
		{ text: 'Splash', value: '/splash' },
		{ text: 'Login', value: '/login' },
		{ text: 'Company Chooser', value: '/login/companyChooser' },
		{ text: 'Dashboard', value: '/dashboard' },
		{ text: 'My Account', value: '/myAccount/OverviewScreen' },
		{ text: 'Permission And Prefrence', value: '/myAccount/permissionAndPrefrence' },
		{ text: 'Permissions', value: '/myAccount/permissions' },
		{ text: 'Prefrences', value: '/myAccount/prefrences' },
		{ text: 'Edit Details', value: '/myAccount/editDetails' },
		{ text: 'Details', value: '/myAccount/details' },
		{ text: 'Edit Address', value: '/myAccount/editAddress' },
		{ text: 'Edit Address Payment', value: '/myAccount/editAddressPayment' },
		{ text: 'Suggested Address', value: '/myAccount/suggestedAddress' },
		{ text: 'Access Info', value: '/myAccount/accessInfo' },
		{ text: 'AddNew Authorized', value: '/myAccount/addNewAuthorized' },
		{ text: 'Products And Services', value: '/ProductsAndServices/MainScreen' },
		{ text: 'Enjoy More Details', value: '/ProductsAndServices/enjoyMoreDetails' },
		{ text: 'Extra List Details', value: '/ProductsAndServices/extraListDetails' },
		{ text: 'Extras Category List', value: '/ProductsAndServices/extrasCategoryList' },
		{ text: 'Terms And Conditions', value: '/ProductsAndServices/termsAndConditions' },
		{ text: 'Purchased Products', value: '/PurchasedProducts' },
		{ text: 'Details', value: '/ProductsAndServices/details' },
		{ text: 'Commitment Contracts', value: '/commitmentContracts' },
		{ text: 'Billing', value: '/billing' },
		{ text: 'Current Consumtopn', value: '/billing/currentConsumption' },
		{ text: 'Topup History', value: '/TopUp/topUpHistory' },
		{ text: 'TopUp Current Spendings', value: '/TopUp/currentSpendingHistory' },
		{ text: 'TopUp Prepaid Service', value: '/TopUp/topUpPrepaidservice' },
		{ text: 'Configure Bill', value: '/billing/billConfig' },
		{ text: 'Billing Details ServiceUsages', value: '/billing/billingDetailsServiceUsages' },
		{ text: 'Billing Details', value: '/billing/billingDetails' },
		{ text: 'Device Invoice', value: '/billing/deviceInvoice' },
		{ text: 'billing Details Bill Shock', value: '/billing/billingDetailsBillShock' },
		{ text: 'eBill', value: '/billing/eBill' },
		{ text: 'Bill Payment', value: '/billing/billPayment' },
		{ text: 'Bill Payment Details', value: '/billing/billPaymentDetails' },
		{ text: 'Billing Creadit Notes', value: '/billing/creditNotes' },
		{ text: 'Settings', value: '/settings' },
		{ text: 'Service Settings', value: '/serviceSettings' },
		{ text: 'Service Settings Navigators', value: '/serviceSettings/navigators' },
		{ text: 'Pinpuk', value: '/serviceSettings/pinpuk' },
		{ text: 'Unlock', value: '/serviceSettings/unlock' },
		{ text: 'Speed Check', value: '/speedCheck' },
		{ text: 'Network Usage', value: '/networkUsage' },
		{ text: 'Device Invoice', value: '/billing/deviceInvoice' },
		{ text: 'Inbox', value: '/Inbox' },
		{ text: 'Inbox Listing', value: '/Inbox/InboxListing' },
		{ text: 'Inbox Details', value: '/Inbox/details' },
		{ text: 'Inbox Configuration', value: '/Inbox/configration' },
		{ text: 'Need Help Bubble', value: 'needHelpBubble' },
		{ text: 'Licenses', value: '/licenses' },
		{ text: 'Rewards', value: '/rewards' },
		{ text: 'Third Party Landing', value: '/serviceSettings/thirdPartyLanding' },
		{ text: 'Line Services', value: '/LineServices' },
		{ text: 'Line Services Call Options', value: '/LineServices/CallOptions/MainPage' },
		{ text: 'Line Services VBY Vodafone', value: '/LineServices/VbyVodafone' },
		{ text: 'Line Services Mobile Connect', value: '/LineServices/MobileConnect' },
		{ text: 'Line Services Restrictions', value: '/LineServices/Restrictions/MainPage' },
		{ text: 'Line Services Restrictions Details', value: '/LineServices/Restrictions/Details' },
		{ text: 'Secure Net', value: '/Securenet' },
		{ text: 'Fault Managment', value: '/FaultManagement/LandingPage' },
		{ text: 'My Orders', value: '/myorders' },
		{ text: 'PQNI', value: '/pqni' },
		{ text: 'Post Generic Section', value: '/billing/billPaymentDetailSec' },
		{ text: 'Pre Generic Section', value: '/TopUp/topUpPrepaidserviceSec' },
		{ text: 'Mis Datos', value: '/myAccount/details' },
		{ text: 'Mis Pagos', value: '/billing/billPaymentDetails' },
		{ text: 'Mis Pedidos', value: '/myorders' },
		{ text: 'Contratos y permanencias', value: '/myAccount/landing/contracts' },
		{ text: 'Notificaciones', value: '/Inbox/InboxListing' },
		{ text: 'Configurar mi factura', value: '/billing/billConfig' },
		{ text: 'Privacidad', value: '/myAccount/landing/privacy' },
		{ text: 'SSO One Number', value: '/sso?product=onenumber' },
		{ text: 'SSO Securenet', value: '/sso?product=securenet' },
		{ text: 'SSO Offers', value: '/sso?product=offers' },
		{ text: 'P&S Mis dispositivos', value: '/ProductsAndServices/Home?pillSelected=devices' },
		{ text: 'P&S Home', value: '/ProductsAndServices/Home' },
	],
	siteStatusList: [
		/** P1 user statuses */
		{ id: 1, itemName: 'Activo' },
		{ id: 2, itemName: 'Pend de Cambio' },
		{ id: 3, itemName: 'Pend de Desconectar' },
		{ id: 12, itemName: 'active_pending' },
		/** P2 user statuses */
		{ id: 4, itemName: 'Suspendido' },
		{ id: 5, itemName: 'Pend de Reconectar' },
		{ id: 6, itemName: 'Desconectado' },
		{ id: 7, itemName: 'Desconectado No Pago' },
		/** P3 user statuses */
		{ id: 8, itemName: 'Cancelado' }, //canceled
		{ id: 9, itemName: 'Pend de Instalar' }, //Pending installation
		{ id: 10, itemName: 'Desconectado No Pago Mas De Seis Meses' }, //Disconnected no payment for more than 6 months
		{ id: 11, itemName: 'Desconectada Mas De Seis Meses' }, //Disconnected for more than 6 months
	],
	customerSegmentList: [
		{ id: 10, itemName: 'Diamond' },
		{ id: 12, itemName: 'Gold' },
		{ id: 13, itemName: 'Platinum' },
		{ id: 14, itemName: 'Vodafone' },
	],
	platformList: [
		{ id: 11, itemName: 'IOS' },
		{ id: 22, itemName: 'Android' },
		{ id: 23, itemName: 'Mobile_Web' },
	],
	consumerList: [
		{ id: 41, itemName: 'Mobile postpaid' },
		{ id: 42, itemName: 'Mobile prepaid' },
		{ id: 43, itemName: 'MBB postpaid' },
		{ id: 45, itemName: 'landline' },
		{ id: 46, itemName: 'ADSL' },
		{ id: 47, itemName: 'Fibre' },
		{ id: 48, itemName: 'TV' },
		{ id: 49, itemName: 'Vodafone En Tu Casa' },
	],
	employeeList: [
		{ id: 55, itemName: 'Mobile postpaid' },
		{ id: 56, itemName: 'MBB postpaid' },
	],
	authorizedList: [
		{ id: 61, itemName: 'Mobile postpaid' },
		{ id: 68, itemName: 'Mobile prepaid' },
		{ id: 62, itemName: 'MBB postpaid' },
		{ id: 63, itemName: 'landline' },
		{ id: 64, itemName: 'ADSL' },
		{ id: 65, itemName: 'Fibre' },
		{ id: 66, itemName: 'TV' },
		{ id: 67, itemName: 'Vodafone En Tu Casa' },
	],
	employeesmeList: [{ id: 69, itemName: 'Mobile postpaid' }],
	p2AndP3SiteStatuses: [
		{ id: 4, itemName: 'Suspendido' },
		{ id: 5, itemName: 'Pend de Reconectar' },
		{ id: 6, itemName: 'Desconectado' },
		{ id: 7, itemName: 'Desconectado No Pago' },
		{ id: 8, itemName: 'Cancelado' },
		{ id: 9, itemName: 'Pend de Instalar Pend' },
		{ id: 10, itemName: 'Desconectado No Pago Mas De Seis Meses' },
		{ id: 11, itemName: 'Desconectada Mas De Seis Meses' },
	],
	p1SiteStatuses: [
		{ id: 1, itemName: 'Activo' },
		{ id: 2, itemName: 'Pend de Cambio' },
		{ id: 3, itemName: 'Pend de Desconectar' },
		{ id: 12, itemName: 'active_pending' },
	],
	accountTypeList: [
		{
			text: 'All',
			value: 'all',
		},
		{
			text: 'Consumer',
			value: 'consumer',
		},
		{
			text: 'Business',
			value: 'employee',
		},
		{
			text: 'Authorized',
			value: 'authorized',
		},
		{
			text: 'Amdocs Business SME',
			value: 'employee sme',
		},
	],
	menuConfigConsumerList: [
		{ text: 'Mobile postpaid', value: 'Mobile postpaid' },
		{ text: 'Mobile prepaid', value: 'Mobile prepaid' },
		{ text: 'MBB postpaid', value: 'MBB postpaid' },
		{ text: 'Landline', value: 'Landline' },
		{ text: 'ADSL', value: 'ADSL' },
		{ text: 'Fibre', value: 'Fibre' },
		{ text: 'TV', value: 'TV' },
		{ text: 'Vodafone En Tu Casa', value: 'Vodafone En Tu Casa' },
	],
	menuConfigEmployeeList: [
		{ text: 'Mobile postpaid', value: 'Mobile postpaid' },
		{ text: 'MBB postpaid', value: 'MBB postpaid' },
	],
	menuConfigAuthorizedList: [
		{ text: 'Mobile postpaid', value: 'Mobile postpaid' },
		{ text: 'Mobile prepaid', value: 'Mobile prepaid' },
		{ text: 'MBB postpaid', value: 'MBB postpaid' },
		{ text: 'Landline', value: 'Landline' },
		{ text: 'ADSL', value: 'ADSL' },
		{ text: 'Fibre', value: 'Fibre' },
		{ text: 'TV', value: 'TV' },
		{ text: 'Vodafone En Tu Casa', value: 'Vodafone En Tu Casa' },
	],
	menuConfigEmployeeSMEList: [{ text: 'Mobile postpaid', value: 'Mobile postpaid' }],
	dropDownTypes: {
		siteStatus: 'siteStatus',
		customerSegment: 'customerSegment',
		consumer: 'consumer',
		authorized: 'authorized',
		employeeSME: 'employee sme',
		employee: 'employee',
		platform: 'platform',
		light: 'light',
		full: 'full',
		all: 'all',
	},
	contingency: {
		route: '/contingency',
	},
	userTypeList: [
		{ id: 0, itemName: 'Unassigned' },
		{ id: 1, itemName: 'Consumer' },
		{ id: 2, itemName: 'Authorized' },
		{ id: 3, itemName: 'Business' },
	],
	serviceTypeList: [
		{ id: 0, itemName: 'Unassigned' },
		{ id: 1, itemName: 'Mobile postpaid' },
		{ id: 2, itemName: 'Mobile prepaid' },
		{ id: 3, itemName: 'MBB postpaid' },
		{ id: 5, itemName: 'landline' },
		{ id: 6, itemName: 'ADSL' },
		{ id: 7, itemName: 'Fibre' },
		{ id: 8, itemName: 'TV' },
		{ id: 9, itemName: 'Vodafone En Tu Casa' },
	],
	profileTypeList: [
		{ id: 0, itemName: 'Unassigned' },
		{ id: 1, itemName: 'Light' },
		{ id: 2, itemName: 'Complete' },
	],
	filterCriteria: {
		user: 'user',
		service: 'service',
		profile: 'profile',
	},
	appNotInstalled: [
		{ text: 'Go to Web portal', value: 'web' },
		{ text: 'Go to Application stores', value: 'AppStore' },
	],
	goToTypes: {
		appStore: 'AppStore',
		webPortal: 'web',
	},
	staticQueryParam: ['apn', 'afl', 'ibi', 'ifl', 'isi', 'ofl'],
	servicesList: [
		{ id: 60, itemName: 'Mobile prepaid' },
		{ id: 61, itemName: 'Mobile postpaid' },
		{ id: 62, itemName: 'MBB postpaid' },
		{ id: 63, itemName: 'MBB Holiday' },
		{ id: 64, itemName: 'landline' },
		{ id: 65, itemName: 'TV' },
		{ id: 66, itemName: 'ADSL' },
		{ id: 67, itemName: 'Fibre' },
		{ id: 68, itemName: 'Vodafone En Tu Casa' },
	],
	technology: [
		{ id: 0, itemName: 'NEBAL' },
		{ id: 1, itemName: 'NEBAF' },
		{ id: 2, itemName: 'NEBAC' },
		{ id: 3, itemName: 'XDSL' },
		{ id: 4, itemName: 'FTTH' },
	],
	digitalSite: [
		{ id: 0, itemName: '0' },
		{ id: 1, itemName: '1' },
		{ id: 2, itemName: '2' },
		{ id: 3, itemName: '3' },
		{ id: 4, itemName: '4' },
		{ id: 5, itemName: '5' },
		{ id: 6, itemName: '6' },
		{ id: 7, itemName: '7' },
		{ id: 8, itemName: '8' },
		{ id: 9, itemName: '9' },
	],

	consumer: 'consumer',
	light: 'light',
	full: 'full',
};
