<div class="admin-top">
	<br />
	<p>{{ 'home.menu-item.0' | translate }}</p>
	<hr />
</div>
<form (ngSubmit)="saveAllSettings()">
	<div class="row">
		<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
			<!-- All Configurations Flags -->

			<div class="toggle" *ngFor="let flagName of flagsNames">
				<p>{{ getFlagText(flagName) }}</p>
				<div class="toggle-btn">
					<sp-toggle-button
						[toggleTooltip]="getFlagTooltip(flagName)"
						[check]="allConfigs[flagName]"
						(option)="flagCheckedChanged($event, flagName)"
					></sp-toggle-button>
				</div>
			</div>

			<div class="clearfix"></div>

			<div class="configuration-btn">
				<button type="submit">{{ 'home.general-config.buttons.text' | translate }}</button>
			</div>
		</div>
	</div>
</form>
