<div class="admin-top">
	<br />
	<p>Configuración Flujos TX - AO</p>
	<hr />
</div>
<form (ngSubmit)="saveAllSettings()">
	<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
		<!-- All Configurations Flags -->

		<div class="toggle" *ngFor="let entrypoint of currentConfig">
			<p>{{ entrypoint.name }}</p>
			<div class="toggle-btn">
				<sp-toggle-button
					[check]="entrypoint.value"
					(option)="flagCheckedChanged($event, entrypoint.code)"
				></sp-toggle-button>
			</div>
		</div>

		<div class="clearfix"></div>
		<div class="configuration-btn">
			<button type="submit">{{ 'home.general-config.buttons.text' | translate }}</button>
		</div>
	</div>
</form>
