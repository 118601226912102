<div class="container-fluid header">
	<div class="row sp-header">
		<div class="col-1 logo">
			<img src="assets/group-3.png" alt="" class="image-responsive" />
		</div>
		<div class="col-11 tabs">
			<div class="col-md-4 col-12 admin">
				<p>{{ headerVariables?.leftText }}</p>
			</div>
			<div *ngIf="headerVariables?.loggedIn" class="offset-md-3 col-md-2 col-12 username">
				<p>{{ headerVariables?.welcomeText }}</p>
			</div>
			<div *ngIf="headerVariables?.loggedIn" class="col-md-1 col-12 logout">
				<button class="logout-btn" (click)="logout()">{{ 'home.logout.text' | translate }}</button>
			</div>
		</div>
	</div>
</div>
