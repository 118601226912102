import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConfigurationService } from '../../common/services/configuration.service';

@Injectable()
export class GeneralConfigResolver implements Resolve<any> {
	constructor(private config: ConfigurationService) {}
	resolve() {
		return this.config.load();
	}
}
