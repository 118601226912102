import { Component, OnInit } from '@angular/core';
import { FlowsConfigService } from '../../common/services/flows-config.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { HomeService } from '../home.service';
import { EntryPoint } from '../../models/flows-config.model';
import { NotificationService } from '../../common/services/notification.service';
import { Notification } from '../../models/notification.model';
import { Error } from '../../models/error.model';
import { ErrorHandlingService } from '../../common/services/errorHandling.service';
import { JSON_PATHS, PAGES } from '../../common/constants/defines';

@Component({
	selector: 'sp-flows-config-ao',
	templateUrl: './flows-config-ao.component.html',
	styleUrls: ['./flows-config-ao.component.scss'],
})
export class FlowsConfigAoComponent implements OnInit {
	public currentConfig: EntryPoint[];
	error: Error;

	constructor(
		private readonly flowsConfigService: FlowsConfigService,
		private readonly homeService: HomeService,
		private readonly notificationService: NotificationService,
		private readonly errorHandling: ErrorHandlingService
	) {}

	ngOnInit() {
		this.flowsConfigService.getEntryPointsConfiguration().subscribe((response) => {
			this.currentConfig = response;
		});
	}

	saveAllSettings(): void {
		const form: any = this;
		this.flowsConfigService.patchConfiguration(this.currentConfig).subscribe(
			(respone) => {
				const notification: Notification = new Notification();
				notification.primaryButtonClick = function (): void {
					form.notificationService.notificationModal.hide();
				};
				notification.bodyContent = 'Configuration Saved Successfully';
				notification.bodyTitle = 'Update Configuration';
				notification.primaryButtonText = 'Close';
				notification.primaryButtonStyle = 'btn registration';
				this.notificationService.createNotification(notification);
			},
			(error) => {
				if (this.error) {
					const notification: Notification = new Notification();
					notification.primaryButtonClick = function (): void {
						form.notificationService.notificationModal.hide();
					};
					notification.bodyContent = 'Something went wrong, data wasn`t saved successfully';
					notification.bodyTitle = 'Error!';
					notification.primaryButtonText = 'Close';
					notification.primaryButtonStyle = 'btn registration';
					this.notificationService.createNotification(notification);
				}
			}
		);
	}

	flagCheckedChanged(event: boolean, keyName: string): void {
		var flowentrypoint: EntryPoint = this.currentConfig.find((entry) => entry.code === keyName);
		flowentrypoint.value = event;
	}
}
