import { Component, OnInit } from '@angular/core';
import { UserPermissionsService } from '../../common/services/user-permissions.service';
import { GroupNames } from '../../common/enums/group-names.enum';
import { UserPermissions } from '../../models/user-permissions.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../common/services/notification.service';
import { Notification } from './../../models/notification.model';

@Component({
	selector: 'sp-user-permissions',
	templateUrl: './user-permissions.component.html',
	styleUrls: ['./user-permissions.component.scss'],
})
export class UserPermissionsComponent implements OnInit {
	permissions: Array<UserPermissions>;
	updatedPermissions: Array<UserPermissions>;
	showUpdateButton: boolean;
	adminSelectAll = false;
	negocioSelectAll = false;
	aoSelectAll = false;
	constructor(
		public userPermissionsService: UserPermissionsService,
		private translateService: TranslateService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.permissions = this.userPermissionsService.userPermissions;
		this.updatedPermissions = JSON.parse(JSON.stringify(this.permissions));
		this.showUpdateButton = false;
	}
	getUserPermission(userGroup) {
		return this.updatedPermissions.find((item) => {
			return item.groupName.toLowerCase() === userGroup.toLowerCase();
		});
	}
	select(groupName, permission) {
		if (groupName === GroupNames.Administrator) {
			this.adminSelectAll = false;
		} else if (groupName === GroupNames.Negocio) {
			this.negocioSelectAll = false;
		} else {
			this.aoSelectAll = false;
		}
		this.updatedPermissions.map((item) => {
			if (item.groupName.toLowerCase() === groupName) {
				item.permissions.map((element) => {
					if (element.resourceName === permission.resourceName) {
						element.enabled = !element.enabled;
					}
				});
			}
		});
		this.showUpdateButton = JSON.stringify(this.permissions) !== JSON.stringify(this.updatedPermissions);
	}
	selectAll(groupName) {
		switch (groupName) {
			case GroupNames.Administrator:
				if (this.adminSelectAll) {
					this.adminSelectAll = false;
					this.changeSelection(false, groupName);
				} else {
					this.adminSelectAll = true;
					this.changeSelection(true, groupName);
				}
				break;
			case GroupNames.Negocio:
				if (this.negocioSelectAll) {
					this.negocioSelectAll = false;
					this.changeSelection(false, groupName);
				} else {
					this.negocioSelectAll = true;
					this.changeSelection(true, groupName);
				}
				break;
			case GroupNames.AO:
				if (this.aoSelectAll) {
					this.aoSelectAll = false;
					this.changeSelection(false, groupName);
				} else {
					this.aoSelectAll = true;
					this.changeSelection(true, groupName);
				}
				break;
			default:
				break;
		}

		this.showUpdateButton = JSON.stringify(this.permissions) !== JSON.stringify(this.updatedPermissions);
	}
	changeSelection(isSelected: boolean, groupName) {
		this.updatedPermissions.map((item) => {
			if (item.groupName.toLowerCase() === groupName) {
				item.permissions.map((permission) => {
					permission.enabled = isSelected;
				});
			}
		});
	}
	editPermissions() {
		this.userPermissionsService.editUserPermissions(this.updatedPermissions).subscribe(
			() => {
				this.showUpdateButton = false;
				const notification = new Notification();
				this.translateService.get('permissions.messages').subscribe((data) => {
					notification.bodyContent = data['success-msg'];
					notification.primaryButtonText = data['close-btn'];
				});
				notification.primaryButtonClick = () => {
					this.notificationService.notificationModal.hide();
				};
				this.notificationService.createNotification(notification);
			},
			(error) => {
				const notification = new Notification();
				this.translateService.get('permissions.messages').subscribe((data) => {
					notification.bodyContent = data['fail-msg'];
					notification.primaryButtonText = data['close-btn'];
				});
				notification.primaryButtonClick = () => {
					this.notificationService.notificationModal.hide();
				};
				this.notificationService.createNotification(notification);
			}
		);
	}
}
