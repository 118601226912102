import { HeaderVariables } from './../models/header-variables.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../common/authentication/authenticate.service';
import { StorageService } from '../common/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../config/pages-config';
import { Error } from '../models/error.model';
import { AppService } from '../app.service';
@Component({
	selector: 'sp-login',
	templateUrl: './sp-login.component.html',
	styleUrls: ['./sp-login.component.scss'],
})
export class SpLoginComponent implements OnInit {
	emailPattern: string;
	innerValue: string;
	passwordText: string;
	id: number;
	editMode = false;
	title: string;
	welcomeText: string;
	loginError: Error;
	isLoginError = false;
	public SAMLRequest: string;
	public cavUrl: string;
	public relayState: string;
	@ViewChild('loginBtn', { static: true }) loginBtn: ElementRef;
	constructor(
		public authenticateService: AuthenticateService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private storageService: StorageService,
		private translateService: TranslateService,
		private appService: AppService
	) {}
	preventSpace(event) {
		const k = event.charCode;
		return k !== 32;
	}
	ngOnInit() {
		this.authenticateService.logout();
		this.translateService.get('login.header-title').subscribe((data) => {
			this.title = data;
		});
		this.translateService.get('header.welcome').subscribe((data) => {
			this.title = data;
		});
		if (this.authenticateService.isAuthenticated()) {
			this.router.navigate([config.home.name]);
			this.appService.loginExpiredModal();
		} else {
			const accessToken = this.activatedRoute.snapshot.queryParams.at;
			if (accessToken) {
				const jws = this.activatedRoute.snapshot.queryParams.jws;
				/** Already logged in */
				this.authenticateService.authenticate(jws, accessToken);

				const headerVariables: HeaderVariables = new HeaderVariables(
					true,
					this.title,
					this.welcomeText + this.storageService.userProfile.name
				);
				this.storageService.changeHeaderPageLeftSideText(headerVariables);
				this.router.navigate([config.home.name]);
				this.appService.loginExpiredModal();
			}
			// else {
			//   this.cavLogin();
			// }
		}
	}
	cavLogin() {
		/** CAV login integration */
		this.authenticateService.getSAMLRequest().subscribe((data) => {
			this.SAMLRequest = data.authnRequest.value;
			this.cavUrl = data.authnRequest.target;
			this.relayState = data.authnRequest.RelayState;
			setTimeout(() => {
				this.loginBtn.nativeElement.click();
			}, 1000);
		});
	}
}
