import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'search',
	pure: false,
})
export class SearchPipe implements PipeTransform {
	transform(arr: any[], key: string, str: string = '') {
		if (arr) {
			return arr.filter(
				(el) =>
					el[key].toLowerCase().indexOf(str.toLowerCase()) > -1 ||
					(el[key] && el[key].toLowerCase().indexOf(str.toLowerCase()) > -1)
			);
		}
		return [];
	}
}
