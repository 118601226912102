<div class="admin-top-title">
	<br />
	<p>{{ 'home.walletInfoCSV.title' | translate }}</p>
	<hr />
</div>

<div class="form-continar">
	<form (ngSubmit)="exportWalletInfo()" [formGroup]="exportWalletInfoForm">
		<div class="row">
			<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
				<div class="row">
					<div class="field-container col-6">
						<p>{{ 'home.walletInfoCSV.start-save-date' | translate }}</p>
						<div class="container">
							<input formControlName="startSaveDate" type="date" class="form-control" />
						</div>
					</div>

					<div class="field-container col-6">
						<p>{{ 'home.walletInfoCSV.end-save-date' | translate }}</p>
						<div class="container">
							<input formControlName="endSaveDate" type="date" class="form-control" />
						</div>
					</div>
				</div>

				<div class="row">
					<div class="field-container col-6">
						<p>{{ 'home.walletInfoCSV.start-expiry-date' | translate }}</p>
						<div class="container">
							<input
								formControlName="startExpiryDate"
								type="month"
								class="form-control"
								id="startExpireDate"
								placeholder="mm/yyyy"
								onChange="this.className=(this.value!='')?'has-value':''"
							/>
						</div>
					</div>
					<div class="field-container col-6">
						<p>{{ 'home.walletInfoCSV.end-expiry-date' | translate }}</p>
						<div class="container">
							<input
								formControlName="endExpiryDate"
								type="month"
								class="form-control"
								id="endExpireDate"
								placeholder="mm/yyyy"
								onChange="this.className=(this.value!='')?'has-value':''"
							/>
						</div>
					</div>
				</div>

				<div class="configuration-btn">
					<button type="submit">{{ 'home.walletInfoCSV.export-btn' | translate }}</button>
				</div>
			</div>
		</div>
	</form>
</div>
