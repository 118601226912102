<div class="admin-top-title">
	<br />
	<p>{{ 'home.update-eventconfigurations.title' | translate }}</p>
	<hr />
</div>
<div class="url-config">
	<form [formGroup]="form">
		<div class="row">
			<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
				<p class="title">Url-Shortener-Ts:</p>
				<div class="space"></div>
				<p class="title">AuditProperties:</p>
				<div class="space"></div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">BootstrapConfig:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text [isRequired]="true" formControlName="urlshortenerAuditBootstrapConfig" class="input-bg"></sp-text>
					</div>
				</div>

				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">Topic Audit:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text [isRequired]="true" formControlName="urlshortenerAuditTopic" class="input-bg"></sp-text>
					</div>
				</div>
				<div class="space"></div>
				<p class="title">Consumer Properties:</p>
				<div class="space"></div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">BootstrapConfig:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text
							[isRequired]="true"
							formControlName="urlshortenerConsumerBootstrapConfig"
							class="input-bg"
						></sp-text>
					</div>
				</div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">Group ID:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text [isRequired]="true" formControlName="urlshortenerConsumerGroupID" class="input-bg"></sp-text>
					</div>
				</div>

				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">IdleBetweenPolls:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text
							[isRequired]="true"
							formControlName="urlshortenerConsumerIdleBetweenPolls"
							class="input-bg"
						></sp-text>
					</div>
				</div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">maxPollRecordsConfig:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text
							[isRequired]="true"
							formControlName="urlshortenerConsumerMaxPollRecordsConfig"
							class="input-bg"
						></sp-text>
					</div>
				</div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">Topic Customer:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text [isRequired]="true" formControlName="urlshortenerConsumerTopic" class="input-bg"></sp-text>
					</div>
				</div>
				<div class="space"></div>
				<p class="title">Producer Properties:</p>
				<div class="space"></div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">bootstrapConfig:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text
							[isRequired]="true"
							formControlName="urlshortenerProducerBootstrapConfig"
							class="input-bg"
						></sp-text>
					</div>
				</div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">Topic Producer:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text [isRequired]="true" formControlName="urlshortenerProducerTopic" class="input-bg"></sp-text>
					</div>
				</div>
				<div class="space"></div>
				<div class="configuration-btn offset-md-1 col-12">
					<div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
						<button class="submit-btn" (click)="submit()" type="submit">Submit</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
