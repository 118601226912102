import { LoggerService } from './logger.service';
import { ErrorHandler, Injectable } from '@angular/core';
@Injectable()
export class GeneralErrorHandler implements ErrorHandler {
	constructor(private logger: LoggerService) {}

	handleError(error: Error) {
		this.logger.error(error.stack);
	}
}
