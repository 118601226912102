import { Criteria } from './criteria.model';

export class ChatConfig {
	constructor(
		public id?: string,
		public name?: string,
		public taggingName?: string,
		public module?: string,
		public criteria?: Criteria[]
	) {}
}
