import { HeaderVariables } from './../../models/header-variables.model';
import { UserProfile } from './../../models/user-profile.model';
import { LOCAL_STORAGE_PREFIX } from './../constants/defines';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class StorageService {
	headerPageLeftSideTextChanged = new Subject<HeaderVariables>();

	public jwt: string;
	public accessToken;
	public refreshToken;
	public accessExpirationTime: Date = null;
	public refreshExpirationTime: Date = null;
	public userProfile: UserProfile;

	/**
	 * add/set item to browser localstorage
	 * @param key the identifier for the localstorage item
	 * @param value the value of localstorage item
	 */
	public setStorage(key: string, value: any) {
		const newKey = LOCAL_STORAGE_PREFIX + '.' + key;
		// Add to localstorage
		if (typeof value === 'object') {
			localStorage.setItem(newKey, JSON.stringify(value));
		} else {
			localStorage.setItem(newKey, String(value));
		}
	}

	/**
	 * read certain item from the session storage or from the cachedSession and
	 * parse the item to json if the item is a stringified object.
	 * @param  {key} The key of the property to be detected
	 * @returns {Object} the returned object holds the value for the detected property
	 */
	public getStorage(key: string) {
		try {
			const item = localStorage.getItem(LOCAL_STORAGE_PREFIX + '.' + key);
			return JSON.parse(item);
		} catch (error) {
			return localStorage.getItem(LOCAL_STORAGE_PREFIX + '.' + key);
		}
	}

	/**
	 * remove certain item from the localStorage and from the cachedSession
	 * @param  {key} The key of the property to be removed
	 */
	public remove(key: string) {
		localStorage.removeItem(LOCAL_STORAGE_PREFIX + '.' + key);
	}

	/**
	 * clear all the localStorage items and the cachedSession items
	 */
	public empty() {
		localStorage.clear();
	}

	/**
	 * list all items in the localStorage
	 * @returns {Object} the returned object holds the cached Session object
	 */
	public listAllItems() {
		const items = [];
		for (const key in localStorage) {
			if (localStorage.hasOwnProperty(key)) {
				const element = localStorage[key];
				items.push(element);
			}
		}
		return items;
	}

	changeHeaderPageLeftSideText(newHeaderVariables: HeaderVariables) {
		this.headerPageLeftSideTextChanged.next(newHeaderVariables);
	}
}
