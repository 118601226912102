<div class="permissions-container" *ngIf="userPermissionsService.userPermissions.length > 0">
	<div class="row titles">
		<span class="module">{{ 'permissions.Modules_Text' | translate }}</span>
		<span class="select-all first"
			><sp-check-box
				[name]="'select-all-admin'"
				[check]="adminSelectAll"
				(option)="selectAll('administrator')"
			></sp-check-box
			><span>{{ 'permissions.Administrator_Text' | translate }}</span></span
		>
		<span class="select-all"
			><sp-check-box
				[name]="'select-all-negocio'"
				[check]="negocioSelectAll"
				(option)="selectAll('negocio')"
			></sp-check-box
			><span>{{ 'permissions.Negocio_Text' | translate }}</span></span
		>
		<span class="select-all"
			><sp-check-box [name]="'select-all-ao'" [check]="aoSelectAll" (option)="selectAll('ao')"></sp-check-box
			><span>{{ 'permissions.AO_Text' | translate }}</span></span
		>
	</div>
	<div class="permissions-table row">
		<ul>
			<li *ngFor="let item of permissions[0].permissions">
				<span class="modules-names">{{ item.resourceName }}</span>
			</li>
		</ul>
		<ul>
			<li *ngFor="let item of getUserPermission('Administrator').permissions">
				<sp-check-box
					[name]="item.resourceName + '-administrator'"
					[check]="item.enabled"
					(option)="select('administrator', item)"
				></sp-check-box>
			</li>
		</ul>
		<ul>
			<li *ngFor="let item of getUserPermission('Negocio').permissions">
				<sp-check-box
					[name]="item.resourceName + '-negocio'"
					[check]="item.enabled"
					(option)="select('negocio', item)"
				></sp-check-box>
			</li>
		</ul>
		<ul>
			<li *ngFor="let item of getUserPermission('AO').permissions">
				<sp-check-box
					[name]="item.resourceName + '-ao'"
					[check]="item.enabled"
					(option)="select('ao', item)"
				></sp-check-box>
			</li>
		</ul>
	</div>
	<button class="save-button" (click)="editPermissions()" *ngIf="showUpdateButton">
		{{ 'permissions.Save_Button' | translate }}
	</button>
</div>
