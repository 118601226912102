export interface ISchamanConfig {
	chatAveria: boolean;
	digitalSite: ['0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'];
	technology: ['FTTH' | 'NEBAL' | 'NEBAF' | 'NEBAC' | 'XDSL' | 'CABLE'];
	netWiFiHelp: '1' | '2' | '3';
	inicioHorarioChatAveria: string;
	finHorarioChatAveria: string;
	grifoTV: IConfigTV[];
	inicioHorarioChatAveriaTV: string;
	finHorarioChatAveriaTV: string;
	grifoHFC: {
		noTmtas: ['0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'];
		tmtas: ['0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'];
	};
	colas: [
		{
			id: null;
			collection: string;
			secuencia: string;
			codFuncional: null;
			codDiario: string;
			codFinDeSemana: string;
			codDiarioNoche: string;
			codFinDeSemanaNoche: string;
			serviceType: string;
			tecnologia: string;
			segmento: string;
		}
	];
	maxNumberProblems: string;
	maxNumberSolutions: string;
	tiempoEsperaSuperWifi: string;
	grifoNuevoFlujoSuperWifi: boolean;
}

export interface IConfigTV {
	codigoServicio:
		| 'FTTH'
		| 'NEBAL'
		| 'NEBAF'
		| 'NEBAC'
		| 'XDSL'
		| 'SMRTV'
		| 'GMTAR'
		| 'SAGE2'
		| 'SAGNF'
		| 'CABLE'
		| 'GMBOX';
	digitalSite: ['0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'];
}

export const SchamanConfigModel: ISchamanConfig = {
	chatAveria: true,
	digitalSite: ['0'],
	technology: ['FTTH'],
	netWiFiHelp: '1',
	inicioHorarioChatAveria: '00:00',
	finHorarioChatAveria: '23:59',
	grifoTV: [
		{ codigoServicio: 'FTTH', digitalSite: ['0'] },
		{ codigoServicio: 'NEBAL', digitalSite: ['0'] },
		{ codigoServicio: 'NEBAF', digitalSite: ['0'] },
		{ codigoServicio: 'NEBAC', digitalSite: ['0'] },
		{ codigoServicio: 'XDSL', digitalSite: ['0'] },
		{ codigoServicio: 'SMRTV', digitalSite: ['0'] },
		{ codigoServicio: 'GMTAR', digitalSite: ['0'] },
		{ codigoServicio: 'SAGE2', digitalSite: ['0'] },
		{ codigoServicio: 'SAGNF', digitalSite: ['0'] },
		{ codigoServicio: 'GMBOX', digitalSite: ['0'] },
		{ codigoServicio: 'CABLE', digitalSite: ['0'] },
	],
	inicioHorarioChatAveriaTV: '00:00',
	finHorarioChatAveriaTV: '23:59',
	grifoHFC: {
		noTmtas: ['0'],
		tmtas: ['0'],
	},
	colas: [
		{
			id: null,
			collection: '',
			secuencia: '',
			codFuncional: null,
			codDiario: '',
			codFinDeSemana: '',
			codDiarioNoche: '',
			codFinDeSemanaNoche: '',
			serviceType: '',
			tecnologia: '',
			segmento: '',
		},
	],
	maxNumberProblems: '4',
	maxNumberSolutions: '4',
	tiempoEsperaSuperWifi: '5',
	grifoNuevoFlujoSuperWifi: true,
};

export const TechnologyModel: string[] = ['FTTH', 'NEBAL', 'NEBAF', 'NEBAC', 'XDSL', 'CABLE'];
export const DecoModel: string[] = ['SMRTV', 'GMTAR', 'SAGE2', 'SAGNF', 'GMBOX'];
