import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../../common/constants/routes-config';
import * as Routes from '../../common/constants/routes-config';
import { EntryPoint } from '../../models/flows-config.model';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable()
export class FlowsConfigService {
	constructor(
		private http: HttpClient,
		private configService: ConfigurationService,
		private storageService: StorageService
	) {}

	private entrypoints: EntryPoint[] = [];

	getEntryPointsConfiguration(): Observable<EntryPoint[]> {
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('Accept', 'application/json');
		const options: { headers: HttpHeaders } = {
			headers: headers,
		};
		return this.http.get(API_URLS.ENTRYPOINTS.ENTRYPOINTS_GET, options).pipe(
			map((response: any[]) => {
				if (response) {
					var configuration: any = this.configService.getConfigurations();

					response.forEach((item) => {
						if (
							this.entrypoints === undefined ||
							this.entrypoints === null ||
							!this.entrypoints.find((entry) => entry.code === item['flow'])
						) {
							var entryflow: EntryPoint = new EntryPoint();
							entryflow.code = item['flow'];
							entryflow.name = item['flowDescription'];
							if (undefined != configuration['flowAvailability'].find((entry) => entry.code === item['flow'])) {
								entryflow.value = configuration['flowAvailability'].find((entry) => entry.code === item['flow']).value;
							} else {
								entryflow.value = true;
							}
							this.entrypoints.push(entryflow);
						}
					});
				}
				return this.entrypoints;
			})
		);
	}

	patchConfiguration(configuration: object): Observable<Object> {
		if (configuration != null && Object.keys(configuration).length > 0) {
			let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/merge-patch+json' });
			headers = headers.append('Authorization', 'Bearer ' + this.storageService.accessToken);
			return this.http
				.patch(API_URLS.ENTRYPOINTS.ENTRYPOINTS_PATCH, JSON.stringify(configuration), { headers: headers })
				.pipe(
					map((response) => {
						return response;
					})
				);
		}
	}
}
