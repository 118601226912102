import {
	Component,
	OnInit,
	NgModule,
	OnChanges,
	ViewEncapsulation,
	forwardRef,
	Input,
	Output,
	EventEmitter,
	ElementRef,
	AfterViewInit,
	Pipe,
	PipeTransform,
	AfterContentChecked,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ListItem, MyException } from './multiselect.model';
import { DropdownSettings } from './multiselect.interface';
import { ClickOutsideDirective } from './clickOutside';
import { ListFilterPipe } from './list-filter';

export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => AngularMultiSelect),
	multi: true,
};
const noop = () => {};

@Component({
	selector: 'angular2-multiselect',
	templateUrl: './multiselect.component.html',
	host: { '[class]': 'defaultSettings.classes' },
	styleUrls: ['./multiselect.component.scss'],
	providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR],
})
export class AngularMultiSelect implements OnInit, AfterContentChecked, ControlValueAccessor {
	@Input()
	data: Array<ListItem>;
	@Input() label = '';
	@Input()
	settings: DropdownSettings;
	icon = '';

	@Output('onSelect')
	onSelect: EventEmitter<ListItem> = new EventEmitter<ListItem>();

	@Output('onDeSelect')
	onDeSelect: EventEmitter<ListItem> = new EventEmitter<ListItem>();

	@Output('onSelectAll')
	onSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<ListItem>>();

	@Output('onDeSelectAll')
	onDeSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<ListItem>>();

	@Input()
	selectedItems: Array<ListItem>;

	public isActive: boolean = false;
	public isSelectAll: boolean = true;
	filter: ListItem = new ListItem();
	defaultSettings: DropdownSettings = {
		singleSelection: false,
		text: 'Select',
		enableCheckAll: true,
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		enableSearchFilter: false,
		maxHeight: 300,
		badgeShowLimit: 999999999999,
		classes: '',
	};
	ngOnInit() {
		this.settings = Object.assign(this.defaultSettings, this.settings);
	}
	onItemClick(item: ListItem, index) {
		let found = this.isSelected(item);
		let limit = this.selectedItems.length < this.settings.limitSelection ? true : false;

		if (!found) {
			if (this.settings.limitSelection) {
				if (limit) {
					this.addSelected(item);
					this.onSelect.emit(item);
				}
			} else {
				this.addSelected(item);
				this.onSelect.emit(item);
			}
		} else {
			this.removeSelected(item);
			this.onDeSelect.emit(item);
		}
		if (this.isSelectAll || this.data.length > this.selectedItems.length) {
			this.isSelectAll = false;
		}
		if (this.data.length == this.selectedItems.length) {
			this.isSelectAll = true;
		}
	}
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	writeValue(value: any) {
		if (value !== undefined && value !== null) {
			if (this.settings.singleSelection) {
				try {
					if (value.length > 1) {
						this.selectedItems = [value[0]];
						throw new MyException(404, {
							msg: 'Single Selection Mode, Selected Items cannot have more than one item.',
						});
					} else this.selectedItems = value;
				} catch (e) {
					console.error(e.body.msg);
				}
			} else {
				if (this.settings.limitSelection) {
					this.selectedItems = value.splice(0, this.settings.limitSelection);
				} else {
					this.selectedItems = value;
				}
			}
		} else {
			this.selectedItems = [];
		}
	}

	//From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	//From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}
	trackByFn(index, item) {
		return item.id;
	}
	isSelected(clickedItem: ListItem) {
		let found = false;
		this.selectedItems.forEach((item) => {
			if (clickedItem.id === item.id) {
				found = true;
			}
		});
		return found;
	}
	private selectedToShow: string;
	addSelected(item: ListItem) {
		if (this.settings.singleSelection) {
			this.selectedItems = [];
			this.selectedItems.push(item);
		} else this.selectedItems.push(item);

		this.onChangeCallback(this.selectedItems);
	}
	removeSelected(clickedItem: ListItem) {
		this.selectedToShow = '';
		for (let i = 0; i < this.selectedItems.length; i++) {
			let item = this.selectedItems[i];
			if (clickedItem.id === item.id) {
				this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
				i--; /** because the selected item decreased by 1 */
			} else {
				this.selectedToShow += item.itemName + ', ';
			}
		}
		if (this.selectedToShow.length > 0) {
			/** remove last (, ) */
			this.selectedToShow = this.selectedToShow.substr(0, this.selectedToShow.length - 2);
			if (this.selectedToShow.length > 13) {
				this.selectedToShow = this.selectedToShow.substr(0, 13) + '...';
			}
		}
		this.onChangeCallback(this.selectedItems);
	}
	toggleDropdown() {
		this.isActive = !this.isActive;
	}
	closeDropdown() {
		this.isActive = false;
	}
	toggleSelectAll() {
		if (!this.isSelectAll) {
			this.selectedItems = [];
			this.selectedItems = this.data.slice();
			this.isSelectAll = true;
			this.onChangeCallback(this.selectedItems);
			this.onSelectAll.emit(this.selectedItems);
		} else {
			this.selectedItems = [];
			this.isSelectAll = false;
			this.onChangeCallback(this.selectedItems);
			this.onDeSelectAll.emit(this.selectedItems);
		}
	}
	ngAfterContentChecked(): void {
		if (this.isActive) {
			this.icon = 'icon-chevron-up';
		} else {
			this.icon = 'icon-chevron-down';
		}
		if (this.selectedItems.length === this.data.length) {
			this.settings.text = 'All';
		} else if (this.selectedItems.length === 0) {
			this.settings.text = 'Select';
		} else {
			this.selectedToShow = '';
			this.selectedItems.map((x) => {
				this.selectedToShow += x.itemName + ', ';
			});
			if (this.selectedToShow.length > 0) {
				/** remove last (, ) */
				this.selectedToShow = this.selectedToShow.substr(0, this.selectedToShow.length - 2);
				if (this.selectedToShow.length > 13) {
					this.selectedToShow = this.selectedToShow.substr(0, 13) + '...';
				}
			}
			this.settings.text = this.selectedToShow;
		}
	}
	areAllSelected() {
		this.isSelectAll = this.selectedItems.length === this.data.length;
		return this.isSelectAll;
	}
}
