import { UrlConfigComponent } from './url-config/url-config.component';
import { MenuConfigComponent } from './menu-config/menu-config.component';
import { ForceUpdateComponent } from './force-update/force-update.component';
import { GeneralConfigBusinessComponent } from './general-config-business/general-config-business.component';
import { config } from './../../config/pages-config';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { URLConfigResolver } from './url-config/url-config.resolver';
import { UglyToGoodLinkComponent } from './ugly-to-good-link/ugly-to-good-link.component';
import { ChatConfigComponent } from './chat-config/chat-config.component';
import { GdprPropretiesComponent } from './gdpr-propreties/gdpr-propreties.component';
import { BonitaConfigComponent } from './bonita-config/bonita-config.component';
import { GeneralConfigAoComponent } from './general-config-ao/general-config-ao.component';
import { FlowsConfigAoComponent } from './flows-config-ao/flows-config-ao.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UserPermissionGuard } from '../common/guards/userPermission.guard';
import { LatestBuildsComponent } from './latest-builds/latest-builds.component';
import { ExportPaymentCsvComponent } from './export-payment-csv/export-payment-csv.component';
import { TopUpDescriminationConfigComponent } from './top-up-descrimination-config/top-up-descrimination-config.component';
import { AddNewPageDeeplinkComponent } from './add-new-page-deeplink/add-new-page-deeplink.component';
import { ProductConfigComponent } from './product-config/product-config.component';
import { ChatModulesPagesConfigComponent } from './chat-modules-pages-config/chat-modules-pages-config.component';
import { AddNewDeeplinkComponent } from './add-new-deeplink/add-new-deeplink.component';
import { SchamanConfigComponent } from './schaman-config/schaman-config.component';
import { ExportWalletInfoComponent } from './export-wallet-info/export-wallet-info.component';
import { UpdateEventconfigurationsComponent } from './update-eventconfigurations/update-eventconfigurations.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		// commented as it is not part of relase 5.6
		// canActivate: [UserPermissionGuard],
		children: [
			{
				path: '',
				redirectTo: config.generalconfigbusiness.name,
			},
			{
				path: config.generalconfigbusiness.name,
				component: GeneralConfigBusinessComponent,
			},
			{
				path: config.generalconfigao.name,
				component: GeneralConfigAoComponent,
			},
			{
				path: config.flowsconfigao.name,
				component: FlowsConfigAoComponent,
			},
			{
				path: config.forceupdate.name,
				component: ForceUpdateComponent,
			},
			{
				path: config.menuconfig.name,
				component: MenuConfigComponent,
			},
			{
				path: config.chatBubbleConfig.name,
				component: ChatConfigComponent,
			},
			{
				path: config.gdprPropertiesconfig.name,
				component: GdprPropretiesComponent,
			},
			{
				path: config.urlconfig.name,
				component: AddNewDeeplinkComponent,
				resolve: {
					config: URLConfigResolver,
				},
			},
			{
				path: config.beautifyLinkconfig.name,
				component: UglyToGoodLinkComponent,
				resolve: {
					subscription: URLConfigResolver,
				},
			},
			{
				path: config.bonitaConfig.name,
				component: BonitaConfigComponent,
			},
			{
				path: config.schamanConfig.name,
				component: SchamanConfigComponent,
			},
			{
				path: config.userPermissions.name,
				component: UserPermissionsComponent,
			},
			{
				path: config.latestBuilds.name,
				component: LatestBuildsComponent,
			},
			{
				path: config.paymentCSV.name,
				component: ExportPaymentCsvComponent,
			},
			{
				path: config.walletInfo.name,
				component: ExportWalletInfoComponent,
			},
			{
				path: config.TopUpDiscrimination.name,
				component: TopUpDescriminationConfigComponent,
			},
			{
				path: config.AddNewPAgeDeepLink.name,
				component: AddNewPageDeeplinkComponent,
			},
			{
				path: config.UpdateEventConfigurations.name,
				component: UpdateEventconfigurationsComponent,
			},
			{
				path: config.productConfig.name,
				component: ProductConfigComponent,
			},
			{
				path: config.ChatModulePageConfig.name,
				component: ChatModulesPagesConfigComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HomeRoutingModule {}
