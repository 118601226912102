import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ConfigurationService } from '../../common/services/configuration.service';
import { BonitaTypes } from '../../common/enums/bonita-types.enum';
import { Notification } from '../../models/notification.model';
import { NotificationService } from '../../common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'sp-bonita-config',
	templateUrl: './bonita-config.component.html',
	styleUrls: ['./bonita-config.component.scss'],
})
export class BonitaConfigComponent implements OnInit {
	constructor(
		private configService: ConfigurationService,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {}
	form: FormGroup;
	configurations: any;
	ngOnInit() {
		this.configurations = this.configService.getBonitaConfigurations();
		this.form = new FormGroup({
			mobileFlow: new FormControl((this.configurations.mobile && this.configurations.mobile.name) || null),
			mobileVersion: new FormControl((this.configurations.mobile && this.configurations.mobile.version) || null),
			internetFlow: new FormControl((this.configurations.internet && this.configurations.internet.name) || null),
			internetVersion: new FormControl((this.configurations.internet && this.configurations.internet.version) || null),
			fixedFlow: new FormControl((this.configurations.fixed && this.configurations.fixed.name) || null),
			fixedVersion: new FormControl((this.configurations.fixed && this.configurations.fixed.version) || null),
			tvFlow: new FormControl((this.configurations.tv && this.configurations.tv.name) || null),
			tvVersion: new FormControl((this.configurations.tv && this.configurations.tv.version) || null),
		});
	}

	submit() {
		const bonitaConfig = [
			{
				type: BonitaTypes.Movil.toString(),
				name: this.form.controls['mobileFlow'].value,
				version: this.form.controls['mobileVersion'].value,
			},
			{
				type: BonitaTypes.Internet.toString(),
				name: this.form.controls['internetFlow'].value,
				version: this.form.controls['internetVersion'].value,
			},
			{
				type: BonitaTypes.Fixed.toString(),
				name: this.form.controls['fixedFlow'].value,
				version: this.form.controls['fixedVersion'].value,
			},
			{
				type: BonitaTypes.TV.toString(),
				name: this.form.controls['tvFlow'].value,
				version: this.form.controls['tvVersion'].value,
			},
		];
		const patchObject = {
			bonitaConfiguration: bonitaConfig,
		};
		this.configService.patchConfiguration(patchObject).subscribe(
			(data) => {
				this.translate.get('home.bonita-config.form.messages').subscribe((data) => {
					const notification = new Notification();
					notification.bodyTitle = data['success-msg'];
					notification.primaryButtonText = data['close-btn'];
					notification.primaryButton = true;
					notification.primaryButtonClick = () => this.notificationService.notificationModal.hide();
					this.notificationService.createNotification(notification);
				});
			},
			(error) => {
				this.translate.get('home.bonita-config.form.messages').subscribe((data) => {
					const notification = new Notification();
					notification.bodyTitle = data['fail-msg'];
					notification.primaryButtonText = data['close-btn'];
					notification.primaryButton = true;
					notification.primaryButtonClick = () => this.notificationService.notificationModal.hide();
					this.notificationService.createNotification(notification);
				});
			}
		);
	}
}
