<div
	bsModal
	#modal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="mySmallModalLabel"
	aria-hidden="true"
	[config]="{ keyboard: false, backdrop: 'static' }"
>
	<div class="small modal-dialog allow-events">
		<div class="modal-content">
			<div class="modal-header" *ngIf="showCloseIcon">
				<button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
					<span class="icon icon-close modal-close"></span>
				</button>
			</div>
		</div>
		<div class="modal-body">
			<div class="model-contanier">
				<span class="{{ bodyIcon }}"> </span>
				<img *ngIf="modelImg" [src]="modelImg" />
				<div class="title col-12" *ngIf="bodyTitle">
					{{ bodyTitle }}
				</div>
				<div class="description col-12" [innerHtml]="bodyContent">
					<p></p>
				</div>

				<div class="row modal-buttons">
					<div *ngIf="secondaryButtonClick">
						<div class="form-group col-12 col-lg-4 col-md-10 offset-md-1 offset-md-1">
							<button (click)="primaryButtonClick()" class="button red two" *ngIf="primaryButtonClick">
								{{ primaryButtonText }}
							</button>
						</div>
						<div class="form-group col-12 col-lg-4 col-md-10 offset-md-1 offset-md-0">
							<button (click)="secondaryButtonClick()" class="button gray two" *ngIf="secondaryButtonClick">
								{{ secondaryButtonText }}
							</button>
						</div>
					</div>
					<div *ngIf="primaryButtonClick && !secondaryButtonClick">
						<div class="form-group">
							<button (click)="primaryButtonClick()" class="button red two" *ngIf="primaryButtonClick">
								{{ primaryButtonText }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
