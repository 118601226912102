import { ChatConfigService } from './common/services/chat-config.service';
import { UrlConfigService } from './common/services/url-config.service';
import { URLConfigResolver } from './home/url-config/url-config.resolver';
import { CustomHttpInterceptor } from './common/utils/custom-http-interceptor.service';
import { SidemenuConfigService } from './common/services/sidemenu-config.service';
import { LogedinGuard } from './common/authentication/logedin.guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
// import { ComponentLoaderFactory, PositioningService } from 'ngx-bootstrap';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared.module';
import { configLoaderFactory } from './common/utils/common.factory';
import { ConfigurationService } from './common/services/configuration.service';
import { FlowsConfigService } from './common/services/flows-config.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthenticateService } from './common/authentication/authenticate.service';
import { StorageService } from './common/services/storage.service';
import { JWTHelper } from './common/services/jwt.helper';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { SpLoginComponent } from './sp-login/sp-login.component';
import { AuthGuard } from './common/authentication/auth.guard';
import { NotificationService } from './common/services/notification.service';
import { SpModalComponent } from './common/components/sp-modal/sp-modal.component';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GeneralConfigResolver } from './home/general-config-business/general-config-business.resolver';
import { ErrorHandlingService } from './common/services/errorHandling.service';
import { ReloadGuard } from './common/guards/reload.guard';
import { LoggerService } from './common/utils/logger.service';
import { GeneralErrorHandler } from './common/utils/GeneralErrorHandler';
import { HomeService } from './home/home.service';
import { UserPermissionsService } from './common/services/user-permissions.service';
import { AppService } from './app.service';
import { UserPermissionGuard } from './common/guards/userPermission.guard';
import { ForceUpdateService } from './home/force-update/force-update.service';
import { LatestBuildsService } from './home/latest-builds/latest-builds.service';
import { UtilsService } from './common/utils/utils.service';
import { PaymentCsvService } from './common/services/payment-csv.service';
import { AddNewPageDeepLinkService } from './common/services/add-new-page-deep-link.service';
import { KeyValueConfigService } from './common/services/key-value-config.service';
import { WalletInfoCSVService } from './common/services/walletInfo-CSV.service';
import { SchamanService } from './common/services/schaman.service';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';
import { UpdateEventconfigurationsComponent } from './home/update-eventconfigurations/update-eventconfigurations.component';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, 'i18n/', '.json');
}
@NgModule({
	declarations: [AppComponent, SpLoginComponent, SpModalComponent, UpdateEventconfigurationsComponent],
	imports: [
		BrowserModule,
		SharedModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		AppRoutingModule,
		CommonModule,
		ModalModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		PopoverModule.forRoot(),
	],
	providers: [
		{ provide: ErrorHandler, useClass: GeneralErrorHandler },
		UrlConfigService,
		URLConfigResolver,
		SidemenuConfigService,
		ConfigurationService,
		NotificationService,
		ErrorHandlingService,
		AddNewPageDeepLinkService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CustomHttpInterceptor,
			multi: true,
		},
		ConfigurationService,
		FlowsConfigService,
		AuthenticateService,
		LogedinGuard,
		StorageService,
		JWTHelper,
		HttpClient,
		AuthGuard,
		ComponentLoaderFactory,
		PositioningService,
		GeneralConfigResolver,
		ReloadGuard,
		LoggerService,
		ChatConfigService,
		HomeService,
		UserPermissionsService,
		AppService,
		UserPermissionGuard,
		ForceUpdateService,
		LatestBuildsService,
		UtilsService,
		PaymentCsvService,
		KeyValueConfigService,
		WalletInfoCSVService,
		SchamanService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
