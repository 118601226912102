<form [formGroup]="latestBuildForm" (ngSubmit)="saveAllSettings()">
	<div class="row">
		<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
			<div class="header-container col-12">
				<h4 id="header-text">{{ 'latest-builds-configuration.latest-builds' | translate }}</h4>
			</div>
			<div class="multi-grids">
				<div class="toggle col-11">
					<div class="header-text-container col-12">
						<h4 id="header-text-versions">{{ 'latest-builds-configuration.Ios' | translate }}</h4>
					</div>

					<div class="before-version-container">
						<div class="row">
							<div class="col-md-6 col-12 col-lg-4">
								<div class="row first-before-version-box">
									<sp-text textType="number" formControlName="iosMajor"></sp-text>
								</div>
							</div>
							<div class="col-md-6 col-12 col-lg-4">
								<div class="row">
									<sp-text textType="number" formControlName="iosMinor"></sp-text>
								</div>
							</div>
							<div class="col-md-6 col-12 col-lg-4">
								<div class="row">
									<sp-text textType="number" formControlName="iosBuild"></sp-text>
								</div>
							</div>
						</div>
					</div>
					<div class="header-text-container col-12">
						<h4 id="header-text-versions">{{ 'latest-builds-configuration.Android' | translate }}</h4>
					</div>
					<div class="to-version-container">
						<div class="row">
							<div class="col-md-6 col-12 col-lg-4">
								<div class="row first-before-version-box">
									<sp-text textType="number" formControlName="androidMajor" innerValue="latestAndroidData.major">{{
										latestAndroidData.major
									}}</sp-text>
								</div>
							</div>
							<div class="col-md-6 col-12 col-lg-4">
								<div class="row">
									<sp-text textType="number" formControlName="androidMinor">{{ latestAndroidData.minor }}</sp-text>
								</div>
							</div>
							<div class="col-md-6 col-12 col-lg-4">
								<div class="row">
									<sp-text textType="number" formControlName="androidBuild">{{ latestAndroidData.build }}</sp-text>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="configuration-btn">
				<button [disabled]="latestBuildForm.invalid && true" type="submit">
					{{ 'force-update-modal-values.save-modal' | translate }}
				</button>
			</div>
		</div>
	</div>
</form>
