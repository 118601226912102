import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { KeyValueConfig } from '../../models/chat-modules-pages-config.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfigurationCategory } from '../../common/enums/configuration-category.enum';
import { KeyValueConfigService } from '../../common/services/key-value-config.service';
import { NotificationService } from '../../common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../../models/notification.model';
import { IDropdown } from '../../common/components/sp-dropdown/IDropdown.interface';

@Component({
	selector: 'sp-chat-modules-pages-config',
	templateUrl: './chat-modules-pages-config.component.html',
	styleUrls: ['./chat-modules-pages-config.component.scss'],
})
export class ChatModulesPagesConfigComponent implements OnInit {
	public showCloseIcon = false;
	public showError = false;
	public configsFormEdit: FormGroup;
	public configsForm: FormGroup;
	filterKey: string;
	chatModules: KeyValueConfig[] = [];
	chatPages: KeyValueConfig[] = [];
	categoriesList: IDropdown[] = [
		{ text: 'Chat Modules', value: ConfigurationCategory.chatModule },
		{ text: 'Chat Pages', value: ConfigurationCategory.chatPage },
	];
	currentCategories: KeyValueConfig[] = [];
	@ViewChild('modal', { static: true }) public modal: ModalDirective;
	editedItem: KeyValueConfig;
	selectedCategory: ConfigurationCategory = ConfigurationCategory.chatModule;
	selectedCategoryEdit: ConfigurationCategory;

	constructor(
		private fb: FormBuilder,
		private keyValueConfigService: KeyValueConfigService,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {
		this.configsForm = this.fb.group({
			key: ['', Validators.required],
			value: ['', Validators.required],
		});
		this.configsFormEdit = this.fb.group({
			key: ['', Validators.required],
			value: ['', Validators.required],
		});
	}

	ngOnInit() {
		this.loadCategories();
	}
	loadCategories() {
		this.chatModules = [];
		this.chatPages = [];
		this.keyValueConfigService.getAllKeyValueConfigs().subscribe(() => {
			this.chatModules = this.keyValueConfigService.allData.filter(
				(cat) => cat.name && cat.name.toLowerCase() === ConfigurationCategory.chatModule.toLowerCase()
			);
			this.chatPages = this.keyValueConfigService.allData.filter(
				(cat) => cat.name && cat.name.toLowerCase() === ConfigurationCategory.chatPage.toLowerCase()
			);
			this.currentCategories = this.getCurrentCategories();
		});
	}
	getCurrentCategories(category?: ConfigurationCategory) {
		return (category || this.selectedCategory) === ConfigurationCategory.chatModule ? this.chatModules : this.chatPages;
	}
	isExists(arr: KeyValueConfig[]) {
		return !!arr.find(
			(el) =>
				el.name === this.selectedCategory &&
				el.key === this.configsForm.controls['key'].value &&
				el.value === this.configsForm.controls['value'].value
		);
	}
	changeCategory(selectedCategory: ConfigurationCategory) {
		this.selectedCategory = selectedCategory;
		this.currentCategories = this.getCurrentCategories();
	}
	addCategory() {
		this.showError = false;
		const arr = this.getCurrentCategories();
		/** to prevent repeating of modules or pages */
		const isItemExists = this.isExists(arr);

		if (isItemExists) {
			this.showError = true;
		} else {
			const item = new KeyValueConfig();
			item.name = this.selectedCategory;
			item.key = this.configsForm.controls['key'].value;
			item.value = this.configsForm.controls['value'].value;

			this.keyValueConfigService.addCategory(item).subscribe(
				() => {
					this.resetForm();
					this.loadCategories();
					this.successHandling();
				},
				(error) => {
					this.errorHendling();
				}
			);
		}
	}

	private errorHendling(isDelete = false) {
		const notification = new Notification();
		const modal = this;
		this.translate.get('home.messages').subscribe((data) => {
			notification.bodyTitle = data['error-title'];
			notification.bodyContent = data[isDelete ? 'menu-item-deleting-error' : 'error-text'];
			notification.primaryButtonText = data['buttons']['sure'];
		});
		notification.primaryButtonStyle = 'btn registration';
		notification.primaryButtonClick = function () {
			modal.notificationService.notificationModal.hide();
		};
		this.notificationService.createNotification(notification);
	}

	resetForm() {
		const controls = this.configsForm.controls;
		controls['key'].setValue('');
		controls['value'].setValue('');
	}

	private successHandling() {
		const notification = new Notification();
		const modal = this;
		this.translate.get('home.successNotification').subscribe((data) => {
			notification.bodyContent = data['success-text'];
			notification.primaryButtonText = data['buttons']['cancel'];
		});
		notification.primaryButtonStyle = 'btn registration';
		notification.primaryButtonClick = function () {
			modal.notificationService.notificationModal.hide();
		};
		this.notificationService.createNotification(notification);
	}

	deleteCategory(category: KeyValueConfig) {
		const notification = new Notification();
		this.translate.get('home.messages').subscribe((data) => {
			notification.bodyTitle = data['delete-message-title'];
			notification.bodyContent = data['delete-text'];
			notification.primaryButtonText = data['buttons']['sure'];
			notification.secondaryButtonText = data['buttons']['cancel'];
		});
		notification.primaryButtonClick = () => {
			this.keyValueConfigService.deleteCategory(category.id).subscribe(
				() => {
					this.successHandling();
					this.loadCategories();
				},
				() => {
					this.errorHendling(true);
				}
			);
			this.notificationService.notificationModal.hide();
		};
		notification.secondaryButtonClick = () => {
			this.notificationService.notificationModal.hide();
		};
		this.notificationService.createNotification(notification);
	}
	editView(category: KeyValueConfig) {
		this.showError = false;
		this.editedItem = category;
		this.setValues(category);
		this.modal.show();
	}

	setValues(category?: KeyValueConfig) {
		const controls = this.configsFormEdit.controls;
		if (category) {
			this.selectedCategoryEdit = category.name;
			controls['key'].setValue(category.key);
			controls['value'].setValue(category.value);
		}
	}
	hide() {
		this.resetForm();
		this.modal.hide();
	}
	updateCategory() {
		const arr = this.getCurrentCategories(this.editedItem.name);
		const otherCategories = arr.filter((el) => el !== this.editedItem);
		const isItemExists = this.isExists(otherCategories);
		if (isItemExists) {
			this.showError = true;
		} else {
			const item = this.editedItem;
			item.name = this.selectedCategoryEdit;
			item.key = this.configsFormEdit.controls['key'].value;
			item.value = this.configsFormEdit.controls['value'].value;

			this.keyValueConfigService.updateCategory(item).subscribe(
				() => {
					this.hide();
					this.loadCategories();
					this.successHandling();
				},
				() => {
					this.errorHendling();
				}
			);
		}
	}
}
