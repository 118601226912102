<div class="admin-top">
	<br />
	<p>{{ 'home.url-config.title' | translate }}</p>
	<hr />
</div>
<div *ngIf="false" class="row">
	<div class="error-url col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
		<span class="icon-warning"></span>
		{{ 'home.url-config.page-one.duplicatedValidation' | translate }}
	</div>
</div>

<div class="url-config">
	<form [formGroup]="addDeepLinkForm" novalidate novalidate="true" (ngSubmit)="addDeepLink()">
		<div class="row justify-content-center">
			<div class="col-offset-2 col-10 deeplink-container">
				<!-- Description -->
				<div class="row">
					<div class="col-3">
						<p class="title">
							{{ 'home.url-config.description.label' | translate }}
						</p>
					</div>
					<div class="col-9 display-flex">
						<div class="col-11 margin-0">
							<sp-text formControlName="description" class="input-bg"></sp-text>
						</div>
						<div class="col-1">
							<span class="tooltip-custom"
								><i class="icon icon-info-circle tooltip-icon"></i
								><span class="tooltip-text">{{ 'home.url-config.description.tooltip' | translate }}</span></span
							>
						</div>
					</div>
				</div>
				<!-- sourceLink -->
				<div class="row">
					<div class="col-3">
						<p class="title">
							{{ 'home.url-config.sourceLink.label' | translate }}
						</p>
					</div>
					<div class="col-9 display-flex">
						<div class="col-11 margin-0">
							<sp-text formControlName="sourceLink" class="input-bg"></sp-text>
						</div>
						<div class="col-1">
							<span class="tooltip-custom"
								><i class="icon icon-info-circle tooltip-icon"></i
								><span class="tooltip-text">{{ 'home.url-config.sourceLink.tooltip' | translate }}</span></span
							>
						</div>
					</div>
				</div>
				<!-- List of selectable Pages Pentella -->
				<div class="row">
					<div class="col-3">
						<p class="title">
							{{ 'home.url-config.screenId.label' | translate }}
						</p>
					</div>
					<div class="col-9 display-flex">
						<div class="col-11 margin-0">
							<sp-dropdown
								[selectedValue]="webSection"
								[defaultOptionText]="'unset'"
								(change)="selectDestination($event.target.value, addDeepLinkForm)"
								[items]="webSectionNames"
								class="app-section"
							>
							</sp-dropdown>
						</div>
						<div class="col-1">
							<span class="tooltip-custom"
								><i class="icon icon-info-circle tooltip-icon"></i
								><span class="tooltip-text">{{ 'home.url-config.screenId.tooltip' | translate }}</span></span
							>
						</div>
					</div>
				</div>

				<!-- Dynamic segments -->
				<div class="row center-row" *ngIf="segmentsArray && segmentsArray.length > 0">
					<div class="col-3">
						<p class="title">{{ 'home.url-config.parameters.label' | translate }}</p>
					</div>

					<div class="col-12 parameter-form add-parameter-padding" formArrayName="segments">
						<div class="row offset-md-3 col-param">
							<div
								*ngFor="let parameterForm of addDeepLinkForm.get('segments')['controls']; let i = index"
								[formGroupName]="i"
								class="col-4 parameter-added-margin dynamic-segment"
							>
								<input
									type="text"
									(change)="changeSegmentValue($event, parameterForm.value.segmentName)"
									[placeholder]="parameterForm.value.segmentName"
									class="form-control parameter-control"
								/>
							</div>
						</div>
					</div>
				</div>

				<!-- Toggle of Universal link -->
				<div class="row display-flex">
					<div class="col-3">
						<p class="title">{{ 'home.url-config.universalLinkActive.label' | translate }}</p>
					</div>
					<div class="col-9 padding-r-0 padding-l-30 toggle-custom">
						<div class="toggle-btn">
							<sp-toggle-button
								[check]="addDeepLinkForm.controls['universalLinkStatus'].value"
								(option)="changeToggle($event, 'universalLinkStatus')"
							></sp-toggle-button
							><span class="tooltip-custom"
								><i class="icon icon-info-circle tooltip-icon"></i
								><span class="tooltip-text">{{ 'home.url-config.universalLinkActive.tooltip' | translate }}</span></span
							>
						</div>
					</div>
				</div>
				<!-- Expiration -->
				<div class="row">
					<div class="col-3">
						<p class="title">
							{{ 'home.url-config.modificaitonsExpiration.label' | translate }}
						</p>
					</div>
					<div class="col-9 display-flex">
						<div class="col-11 margin-0">
							<sp-text
								formControlName="expiration"
								textType="date"
								class="input-bg"
								[inputValue]="addDeepLinkForm.controls['expiration'].value"
							></sp-text>
						</div>
						<div class="col-1">
							<span class="tooltip-custom"
								><i class="icon icon-info-circle tooltip-icon"></i
								><span class="tooltip-text">{{
									'home.url-config.modificaitonsExpiration.tooltip' | translate
								}}</span></span
							>
						</div>
					</div>
				</div>
				<!-- Toggle of Go to market -->
				<div class="row display-flex">
					<div class="col-3">
						<p class="title">{{ 'home.url-config.gotomarket.label' | translate }}</p>
					</div>
					<div class="col-9 padding-r-0 padding-l-30 toggle-custom">
						<div class="toggle-btn">
							<sp-toggle-button
								[check]="addDeepLinkForm.controls['gotoMarket'].value"
								(option)="changeToggle($event, 'gotoMarket')"
							></sp-toggle-button
							><span class="tooltip-custom"
								><i class="icon icon-info-circle tooltip-icon"></i
								><span class="tooltip-text">{{ 'home.url-config.gotomarket.tooltip' | translate }}</span></span
							>
						</div>
					</div>
				</div>
				<!-- Analytics Parameters -->
				<div class="row parameters-pos">
					<div class="col-3">
						<p class="title">{{ 'home.url-config.analyticParameters.label' | translate }}</p>
					</div>
					<div class="col-9 display-flex">
						<div class="col-lg-11 margin-0">
							<sp-text formControlName="analyticParameters" class="input-bg"></sp-text>
						</div>
						<div class="col-1">
							<span class="tooltip-custom"
								><i class="icon icon-info-circle tooltip-icon"></i
								><span class="tooltip-text">{{ 'home.url-config.parameters.tooltip' | translate }}</span></span
							>
						</div>
					</div>
				</div>

				<!-- Buttons -->

				<div class="clearfix"></div>
				<div class="configuration-btn offset-md-1 col-12">
					<button type="button" (click)="resetForm()">
						{{ 'home.url-config.page-one.buttons.reset' | translate }}
					</button>
					<button
						class="blue-add ajust-button"
						[disabled]="!addDeepLinkForm.valid"
						[ngClass]="{ 'gray-add': !addDeepLinkForm.valid, 'blue-add': addDeepLinkForm.valid }"
						type="submit"
					>
						{{ 'home.url-config.page-one.buttons.addLink' | translate }}
					</button>
				</div>
			</div>
		</div>
	</form>
	<div class="row justify-content-center" *ngIf="uRLConfig.urls && uRLConfig.urls.length > 0">
		<div class="col-offset-2 col-10 deeplink-container table-urls">
			<div class="search-container">
				<div class="row">
					<div class="col-9">
						<sp-text class="search-url" [(ngModel)]="filterChar"></sp-text>
						<span class="icon icon-search"></span>
						<div class="clearfix"></div>
					</div>
					<div class="col-2">
						<button class="btn btn-radius" (click)="exportCSV()">
							<span class="icon icon-download"> Export a CSV</span>
						</button>
					</div>
				</div>
			</div>
			<div *ngFor="let url of uRLConfig.urls | filter : filterChar">
				<!-- parsmeters section  -->
				<div class="url-container" *ngIf="selectedURL !== url">
					<div *ngIf="!url.altUrl && !url.alwaysWeb">
						<!-- in case old url this Form will appear -->
						<div class="row url-desc">
							<div class="col-4">{{ 'home.url-config.description.label' | translate }}</div>
							<div class="col-8 pl-0">{{ url.name }}</div>
						</div>
						<div class="row url-desc">
							<div class="col-4">{{ 'home.url-config.sourceLink.label' | translate }}*</div>
							<div class="col-8 pl-0">{{ url.sourceLink }}</div>
						</div>
						<div class="row url-desc">
							<div class="col-4">{{ 'home.url-config.screenId.label' | translate }}*</div>
							<div class="col-8 pl-0">{{ url.destination }}</div>
						</div>
						<div class="row url-desc url-desc-btn-container">
							<button class="Delete-btn" (click)="deleteURL(url)">Delete</button>
							<button class="Edit-btn" (click)="editView(url)">Edit</button>
						</div>
					</div>
					<!-- in case New url this Form will appear -->
					<div *ngIf="(url.altUrl || url.alwaysWeb) && selectedURL !== url">
						<div class="row url-desc">
							<div class="col-4">Name:</div>
							<div class="col-8 pl-0">{{ url.name }}</div>
						</div>
						<div class="row url-desc">
							<div class="col-4">New Short Link:</div>
							<div class="col-8 pl-0">{{ url.sourceLink }}</div>
						</div>
					</div>
				</div>
				<!-- details of sections -->
				<div *ngIf="selectedURL === url" class="url-container url-container-edit">
					<div class="row url-desc" *ngIf="url.name">
						<div class="col-4">Name:</div>
						<div class="col-8 pl-0">{{ url.name }}</div>
					</div>
					<div class="row url-desc" *ngIf="url.sourceLink">
						<div class="col-4">{{ 'home.url-config.page-one.fields.oldURL' | translate }}</div>
						<div class="col-8 pl-0">{{ url.sourceLink }}</div>
					</div>
					<div class="row url-desc">
						<div *ngIf="urlParamters.length">
							<div class="col-4">New short Link :</div>
							<div class="col-8 pl-0 Showz">
								URL/short name
								<a class="blue-txt-parameters" (click)="showLinkParam()"
									>{{ isShowLinkParm ? 'Hide' : 'Show' }} Parameters</a
								>
							</div>
						</div>
						<div class="row url-desc" *ngIf="isShowLinkParm">
							<div class="col-9">
								<div
									*ngFor="let parameter of urlParamters; let i = index"
									class="col-4 parameter-added-margin pl-0 mt-10"
								>
									<input
										type="text"
										readonly
										placeholder="Parameter Name"
										class="form-control parameter-control"
										[value]="parameter.value.ParameterName"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="row url-desc">
						<div class="col-4">{{ 'home.url-config.page-one.fields.sectionWeb' | translate }}</div>
						<div class="col-8 pl-0">{{ url.sectionWeb }}</div>
					</div>
					<div class="row url-desc">
						<div class="col-4">{{ 'home.url-config.page-one.fields.if-not-installed' | translate }}</div>
						<div class="col-8 pl-0">{{ url.altUrl }}</div>
					</div>
					<div class="row url-desc">
						<div class="col-4">{{ 'home.url-config.page-two.alwaysWeb' | translate }}</div>
						<div class="col-8 pl-0 blue-txt">{{ url.alwaysWeb }}</div>
					</div>
					<div class="row url-desc url-desc-btn-container">
						<button class="Delete-btn" (click)="deleteURL(url)">Delete</button>
						<button class="Edit-btn" (click)="editView(url)">Edit</button>
					</div>
					<!-- </div> -->
				</div>
				<!-- details of sections end -->
			</div>
		</div>
	</div>
</div>

<div
	bsModal
	#modal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="mySmallModalLabel"
	aria-hidden="true"
	[config]="{ keyboard: false, backdrop: 'static' }"
>
	<div *ngIf="isEditMode" class="small modal-dialog allow-events">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close pull-right" aria-label="Close" (click)="hideEditView()">
					<span class="icon icon-close modal-close"></span>
				</button>
			</div>
		</div>
		<div class="url-config">
			<div class="modal-edit toggle-container">
				<form [formGroup]="editDeepLinkForm" novalidate novalidate="true" (ngSubmit)="editDeepLink()">
					<div class="row">
						<div class="col-lg-10 offset-md-1 col-md-10 offset-md-1 col-12 deeplink-container">
							<!-- Description -->
							<div class="row">
								<div class="col-lg-3">
									<p class="title">
										{{ 'home.url-config.description.label' | translate }}
									</p>
								</div>
								<div class="col-lg-9">
									<div class="col-lg-11 margin-0">
										<sp-text formControlName="description" class="input-bg"></sp-text>
									</div>
									<div class="col-lg-1">
										<span class="tooltip-custom"
											><i class="icon icon-info-circle tooltip-icon"></i
											><span class="tooltip-text">{{ 'home.url-config.description.tooltip' | translate }}</span></span
										>
									</div>
								</div>
							</div>
							<!-- sourceLink -->
							<div class="row">
								<div class="col-lg-3">
									<p class="title">
										{{ 'home.url-config.sourceLink.label' | translate }}
									</p>
								</div>
								<div class="col-lg-9">
									<div class="col-lg-11 margin-0">
										<sp-text formControlName="sourceLink" class="input-bg"></sp-text>
									</div>
									<div class="col-lg-1">
										<span class="tooltip-custom"
											><i class="icon icon-info-circle tooltip-icon"></i
											><span class="tooltip-text">{{ 'home.url-config.sourceLink.tooltip' | translate }}</span></span
										>
									</div>
								</div>
							</div>
							<!-- List of selectable Pages Pentella -->
							<div class="row">
								<div class="col-lg-3">
									<p class="title">
										{{ 'home.url-config.screenId.label' | translate }}
									</p>
								</div>
								<div class="col-lg-9">
									<div class="col-lg-11 margin-0">
										<sp-dropdown
											[selectedValue]="editDeepLinkForm.controls.destination.value"
											(change)="selectDestination($event.target.value, editDeepLinkForm)"
											[items]="webSectionNames"
											class="app-section"
										>
										</sp-dropdown>
									</div>
									<div class="col-lg-1">
										<span class="tooltip-custom"
											><i class="icon icon-info-circle tooltip-icon"></i
											><span class="tooltip-text">{{ 'home.url-config.screenId.tooltip' | translate }}</span></span
										>
									</div>
								</div>
							</div>

							<!-- Dynamic segments -->
							<div class="row center-row" *ngIf="segmentsArray && segmentsArray.length > 0">
								<div class="col-lg-3">
									<p class="title">{{ 'home.url-config.parameters.label' | translate }}</p>
								</div>

								<div class="col-12 parameter-form add-parameter-padding" formArrayName="segments">
									<div class="row offset-md-3 col-param">
										<div
											*ngFor="let parameterForm of editDeepLinkForm.get('segments')['controls']; let i = index"
											[formGroupName]="i"
											class="col-4 parameter-added-margin dynamic-segment"
										>
											<input
												type="text"
												(change)="changeSegmentValue($event, parameterForm.value.segmentName, editDeepLinkForm)"
												[placeholder]="parameterForm.value.segmentName"
												class="form-control parameter-control"
											/>
										</div>
									</div>
								</div>
							</div>

							<!-- Toggle of Universal link -->
							<div class="row center-row">
								<div class="col-lg-3">
									<p class="title">{{ 'home.url-config.universalLinkActive.label' | translate }}</p>
								</div>
								<div class="col-lg-9 padding-r-0 padding-l-30 toggle-custom">
									<div class="toggle-btn">
										<sp-toggle-button
											[check]="editDeepLinkForm.controls.universalLinkStatus.value"
											(option)="changeToggle($event, 'universalLinkStatus', editDeepLinkForm)"
										></sp-toggle-button
										><span class="tooltip-custom"
											><i class="icon icon-info-circle tooltip-icon"></i
											><span class="tooltip-text">{{
												'home.url-config.universalLinkActive.tooltip' | translate
											}}</span></span
										>
									</div>
								</div>
							</div>
							<!-- Expiration -->
							<div class="row">
								<div class="col-lg-3">
									<p class="title">
										{{ 'home.url-config.modificaitonsExpiration.label' | translate }}
									</p>
								</div>
								<div class="col-lg-9">
									<div class="col-lg-11 margin-0">
										<sp-text formControlName="expirationEdit" textType="date" class="input-bg"></sp-text>
									</div>
									<div class="col-lg-1">
										<span class="tooltip-custom"
											><i class="icon icon-info-circle tooltip-icon"></i
											><span class="tooltip-text">{{
												'home.url-config.modificaitonsExpiration.tooltip' | translate
											}}</span></span
										>
									</div>
								</div>
							</div>
							<!-- Toggle of Go to market -->
							<div class="row center-row">
								<div class="col-lg-3">
									<p class="title">{{ 'home.url-config.gotomarket.label' | translate }}</p>
								</div>
								<div class="col-lg-9 padding-r-0 padding-l-30 toggle-custom">
									<div class="toggle-btn">
										<sp-toggle-button
											[check]="editDeepLinkForm.controls.gotoMarket.value"
											(option)="changeToggle($event, 'gotoMarket', editDeepLinkForm)"
										></sp-toggle-button
										><span class="tooltip-custom"
											><i class="icon icon-info-circle tooltip-icon"></i
											><span class="tooltip-text">{{ 'home.url-config.gotomarket.tooltip' | translate }}</span></span
										>
									</div>
								</div>
							</div>
							<!-- Analytics Parameters -->
							<div class="row parameters-pos">
								<div class="col-lg-3">
									<p class="title">{{ 'home.url-config.analyticParameters.label' | translate }}</p>
								</div>
								<div class="col-lg-9">
									<div class="col-lg-11 margin-0">
										<sp-text formControlName="analyticParameters" class="input-bg"></sp-text>
									</div>
									<div class="col-lg-1">
										<span class="tooltip-custom"
											><i class="icon icon-info-circle tooltip-icon"></i
											><span class="tooltip-text">{{ 'home.url-config.parameters.tooltip' | translate }}</span></span
										>
									</div>
								</div>
							</div>

							<!-- Buttons -->

							<div class="clearfix"></div>
							<div class="configuration-btn offset-md-1 col-12">
								<button
									class="blue-add"
									[disabled]="!editDeepLinkForm.valid"
									[ngClass]="{ 'gray-add': !editDeepLinkForm.valid, 'blue-add': editDeepLinkForm.valid }"
									type="submit"
								>
									{{ 'home.url-config.page-one.buttons.editLink' | translate }}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
