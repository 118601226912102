import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Error } from '../../models/error.model';
/**
 * ErrorHandling Service Used to load application ErrorHandling from middleware
 * middleware URL is defined in the application enviroment settings.
 */
@Injectable()
export class ErrorHandlingService {
	content: any;
	/**
	 * constructor inject http service in to it
	 */
	constructor(private translateService: TranslateService) {}
	public lookUpError(page: string, errorCode: string): Error {
		const error: Error = new Error();
		if (!errorCode) {
			error.title = 'ERROR';
			error.description = 'Something went wrong, please try again later';
			error.confirmButtonText = 'OK';
			return error;
		}
		this.translateService.get(page + '.messagesList').subscribe((data) => {
			this.content = data;
			if (this.content && this.content.length > 0) {
				const messagesList = this.content;
				const errorObj = messagesList.filter(function (message) {
					return message.errorCode === errorCode.toString();
				});
				if (errorObj.length > 0) {
					error.title = errorObj[0].title;
					error.description = errorObj[0].description;
					error.errorCode = errorObj[0].errorcode;
					error.httpStatus = errorObj[0].httpStatus;
					error.confirmButtonText = errorObj[0].confirmButton.text;
					error.dismissButtonText = errorObj[0].dismissButton.text;
					return error;
				} else {
					error.title = 'ERROR';
					error.description = 'Something went wrong, please try again later';
					error.confirmButtonText = 'OK';
					return error;
				}
			}
		});
		return error;
	}
}
