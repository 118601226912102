import { map } from 'rxjs/operators';
import { config } from './../../../config/pages-config';
import { newURLConfig, ModificationDate } from './../../models/url.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Routes from '../constants/routes-config';
import { JSON_PATHS, locationHeader } from '../constants/defines';
import * as jsonQuery from 'jsonpath';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UrlConfigService {
	urls: newURLConfig[] = [];
	constructor(private http: HttpClient) {}
	getAllURLS() {
		this.urls = [];

		return this.http.get(Routes.API_URLS.URLSConfig.CONFIG_URL).pipe(
			map((response: any[]) => {
				const items = jsonQuery.value(response, JSON_PATHS.URLITEMS.ITEMS);
				if (items) {
					items.map((res) => {
						const url = new newURLConfig();
						url.id = jsonQuery.value(res, JSON_PATHS.URLITEMS.ID) || '';
						url.name = jsonQuery.value(res, JSON_PATHS.URLITEMS.NAME) || '';
						url.sourceLink = jsonQuery.value(res, JSON_PATHS.URLITEMS.SOURCELINK) || '';
						url.destination = jsonQuery.value(res, JSON_PATHS.URLITEMS.DESTINATION) || '';
						url.gotomarket = jsonQuery.value(res, JSON_PATHS.URLITEMS.GOTOMARKET) || false;
						url.status = jsonQuery.value(res, JSON_PATHS.URLITEMS.STATUS) || '';
						url.modifications = new ModificationDate();
						url.modifications.expiration = jsonQuery.value(res, JSON_PATHS.URLITEMS.EXPIRATION) || '';
						url.included = jsonQuery.value(res, JSON_PATHS.URLITEMS.INCLUDE) || [];
						url.serviceTypes = jsonQuery.value(res, JSON_PATHS.URLITEMS.SERVICETYPES) || [];
						this.urls.push(url);
					});
				}
			})
		);
	}
	addURL(url) {
		let headers = new HttpHeaders();
		headers = headers.append('accept', 'application/json');

		return this.http
			.post(Routes.API_URLS.URLSConfig.CONFIG_URL, url, {
				observe: 'response',
				headers: headers,
				responseType: 'text' as 'text',
			})
			.pipe(
				map((res) => {
					if (res.headers.get(locationHeader.Location) && res.headers.get(locationHeader.Location).split('/').length) {
						url.id = res.headers.get(locationHeader.Location).split('/').splice(-1).toString();
					}
					if (url.destination && !url.name) {
						url.name = config.internalRoutesList.find((el) => el.value === url.destination)
							? config.internalRoutesList.find((el) => el.value === url.destination).text
							: '';
					}
					this.urls.push(url);
					return url.id;
				})
			);
	}

	addNewURL(url) {
		let headers = new HttpHeaders();
		headers = headers.append('accept', 'application/json');

		return this.http
			.post(Routes.API_URLS.URLSConfig.CONFIG_URL, url, {
				observe: 'response',
				headers: headers,
				responseType: 'text' as 'text',
			})
			.pipe(
				map((res) => {
					if (res) {
						url.id = res.headers.get(locationHeader.Location).split('/').splice(-1).toString();
					}
				})
			);
	}
	updateURL(body) {
		const url = Routes.API_URLS.URLSConfig.CONFIG_URL_BY_ID.replace('{id}', body.id);
		return this.http.put(url, body).pipe(map((response) => {}));
	}
	deleteURL(body) {
		const url = Routes.API_URLS.URLSConfig.CONFIG_URL_BY_ID.replace('{id}', body.id);
		return this.http.delete(url, body).pipe(map((response) => {}));
	}
}
