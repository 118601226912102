import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BuildNumberInterface } from './force-update.interface';
import { osType } from '../../common/constants/defines';
import { UtilsService } from '../../common/utils/utils.service';

let utilsService = new UtilsService();

export function BuildRangeAndVersionRule(control: AbstractControl) {
	// check if the from is less or equal to TO version build number
	const from = control.get('from');
	const to = control.get('to');

	// check Version Rule if valed and one of them is entered the pass if not send error versionRule
	if (!from.dirty && !from.touched && !to.dirty && !to.touched) {
		return null;
	} else if (from.valid || to.valid) {
		// check build range
		if (!isBiggerOrEqual(from.value, to.value)) {
			return { wrongRange: true };
		}

		if (ValidateBuildNumber(to) !== null) {
			if (from.valid && !to.hasError('fillAll')) {
				to.setErrors(null);
			} else {
				to.setErrors(ValidateBuildNumber(to));
			}
		}
		if (ValidateBuildNumber(from) !== null) {
			if (to.valid && !from.hasError('fillAll')) {
				from.setErrors(null);
			} else {
				from.setErrors(ValidateBuildNumber(from));
			}
		}

		return null;
	}
	return { versionRule: true };
}

export function isBiggerOrEqualThanLatestBuild(latestBuild: any): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const from = control.get('version').get('from');
		const to = control.get('version').get('to');
		const currentOSType = control.get('osType');

		if (!from.dirty && !from.touched && !to.dirty && !to.touched) {
			return null;
		} else if ((from.valid || to.valid) && currentOSType.value) {
			if (currentOSType.value.toLowerCase() === osType.android.toLowerCase()) {
				return checkLatestBuildLimit(utilsService.versionFormat(latestBuild.android));
			} else if (currentOSType.value.toLowerCase() === osType.ios.toLowerCase()) {
				return checkLatestBuildLimit(utilsService.versionFormat(latestBuild.ios));
			} else if (currentOSType.value.toLowerCase() === osType.all.toLowerCase()) {
				return (
					checkLatestBuildLimit(utilsService.versionFormat(latestBuild.android)) ||
					checkLatestBuildLimit(utilsService.versionFormat(latestBuild.ios))
				);
			}
			return null;
		}
		return null;

		function checkLatestBuildLimit(latestBuildNumber) {
			// check build range
			if (!isBiggerOrEqual(from.value, latestBuildNumber) || !isBiggerOrEqual(to.value, latestBuildNumber)) {
				return { biggerThanLatestBuild: true };
			}
		}
	};
}
export function ValidateBuildNumber(control: AbstractControl) {
	// check if the from is less or equal to TO version build number

	// add parseint to convert string to munbers
	// i have to make sure that all values is in numper type not string
	const major = parseInt(control.get('major').value);
	const minor = parseInt(control.get('minor').value);
	const build = parseInt(control.get('build').value);

	// All 3 text box for the FROM or TO versions should be filled, and in case of any missing field the field should be highlighted with red boarders
	// add isNumber to add new case which is if the value is Zero then make sure to return true in stead of false
	if ((!isNaN(major) || !isNaN(minor) || !isNaN(build)) && !(!isNaN(major) && !isNaN(minor) && !isNaN(build))) {
		return { fillAll: true };
	}
	if (!(!isNaN(major) && !isNaN(minor) && !isNaN(build))) {
		return { invalid: true };
	}
	return null;
}

export function isBiggerOrEqual(small: BuildNumberInterface, big: BuildNumberInterface): boolean {
	const isSmallEmpty = typeof big !== 'undefined' ? utilsService.checkPropertiesNotNull(small) : true;
	const isBigEmpty = typeof big !== 'undefined' ? utilsService.checkPropertiesNotNull(big) : true;

	if (isSmallEmpty || isBigEmpty) return true;

	// - make sure that big biger than small
	if (parseInt(small.major.toString()) < parseInt(big.major.toString())) {
		return true;
	} else if (parseInt(small.major.toString()) === parseInt(big.major.toString())) {
		if (parseInt(small.minor.toString()) < parseInt(big.minor.toString())) {
			return true;
		} else if (parseInt(small.minor.toString()) === parseInt(big.minor.toString())) {
			if (parseInt(small.build.toString()) < parseInt(big.build.toString())) {
				return true;
			} else if (parseInt(small.build.toString()) === parseInt(big.build.toString())) {
				return true;
			}
		}
	}
	return false;
}
