import { Router } from '@angular/router';
import { AuthenticateService } from './../../authentication/authenticate.service';
import { HeaderVariables } from './../../../models/header-variables.model';
import { Subscription } from 'rxjs';
import { StorageService } from './../../services/storage.service';
import { Component, OnInit, OnDestroy, AfterContentChecked } from '@angular/core';
import { JWTHelper } from '../../services/jwt.helper';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../config/pages-config';
import { LOCAL_STORAGE_KEYS } from '../../constants/defines';

@Component({
	selector: 'sp-header',
	templateUrl: './sp-header.component.html',
	styleUrls: ['./sp-header.component.scss'],
})
export class SpHeaderComponent implements OnInit, OnDestroy, AfterContentChecked {
	public headerVariables: HeaderVariables = new HeaderVariables(false);
	private subscription: Subscription;
	constructor(
		private storageService: StorageService,
		private authenticateService: AuthenticateService,
		private translateService: TranslateService,
		private router: Router
	) {}

	ngOnInit() {
		this.subscription = this.storageService.headerPageLeftSideTextChanged.subscribe(
			(newHeaderVariables: HeaderVariables) => {
				this.headerVariables = newHeaderVariables;
			}
		);
	}
	ngAfterContentChecked(): void {
		let name = null;
		name = this.storageService.getStorage(LOCAL_STORAGE_KEYS.USERNAME);
		if (name) {
			this.headerVariables.loggedIn = true;
			this.translateService.get('home.general-config.header-title').subscribe((data) => {
				this.headerVariables.leftText = data;
			});
			this.translateService.get('home.header.welcome').subscribe((data) => {
				this.headerVariables.welcomeText = data + ' ' + name;
			});
		}
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	logout() {
		this.authenticateService.logout();
		this.headerVariables = new HeaderVariables(false, 'Administrador Login');
		this.storageService.changeHeaderPageLeftSideText(this.headerVariables);
		this.router.navigate([config.login.route]);
	}
}
