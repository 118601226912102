import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpRadioButtonComponent } from '../common/components/sp-radio-button/sp-radio-button.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SharedModule } from '../common/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GeneralConfigBusinessComponent } from './general-config-business/general-config-business.component';
import { ForceUpdateComponent } from './force-update/force-update.component';
import { MenuConfigComponent } from './menu-config/menu-config.component';
import { TranslateModule } from '@ngx-translate/core';
import { UrlConfigComponent } from './url-config/url-config.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UglyToGoodLinkComponent } from './ugly-to-good-link/ugly-to-good-link.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChatConfigComponent } from './chat-config/chat-config.component';
import { GdprPropretiesComponent } from './gdpr-propreties/gdpr-propreties.component';
import { BonitaConfigComponent } from './bonita-config/bonita-config.component';
import { GeneralConfigAoComponent } from './general-config-ao/general-config-ao.component';
import { FlowsConfigAoComponent } from './flows-config-ao/flows-config-ao.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { LatestBuildsComponent } from './latest-builds/latest-builds.component';
import { ExportPaymentCsvComponent } from './export-payment-csv/export-payment-csv.component';
import { TopUpDescriminationConfigComponent } from './top-up-descrimination-config/top-up-descrimination-config.component';
import { AddNewPageDeeplinkComponent } from './add-new-page-deeplink/add-new-page-deeplink.component';
import { ProductConfigComponent } from './product-config/product-config.component';
import { ChatModulesPagesConfigComponent } from './chat-modules-pages-config/chat-modules-pages-config.component';
import { AddNewDeeplinkComponent } from './add-new-deeplink/add-new-deeplink.component';
import { SchamanConfigComponent } from './schaman-config/schaman-config.component';
import { ExportWalletInfoComponent } from './export-wallet-info/export-wallet-info.component';
@NgModule({
	imports: [
		CommonModule,
		HomeRoutingModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		ModalModule,
		TypeaheadModule.forRoot(),
	],
	declarations: [
		HomeComponent,
		GeneralConfigBusinessComponent,
		SpRadioButtonComponent,
		ForceUpdateComponent,
		MenuConfigComponent,
		UrlConfigComponent,
		UglyToGoodLinkComponent,
		ChatConfigComponent,
		GdprPropretiesComponent,
		BonitaConfigComponent,
		GeneralConfigAoComponent,
		UserPermissionsComponent,
		LatestBuildsComponent,
		TopUpDescriminationConfigComponent,
		ExportPaymentCsvComponent,
		AddNewPageDeeplinkComponent,
		ProductConfigComponent,
		ChatModulesPagesConfigComponent,
		AddNewDeeplinkComponent,
		SchamanConfigComponent,
		ExportWalletInfoComponent,
		FlowsConfigAoComponent,
	],
	providers: [],
})
export class HomeModule {}
