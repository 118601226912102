import { UrlType } from '../common/enums/url-type.enum';
export class URLConfig {
	public id?: string;
	public name?: string;
	public sourceLink?: string;
	public destination?: string;
	public urlType?: UrlType;
	public alwaysWeb?: boolean;
	public altUrl?: string;
	public sectionWeb?: string;
	public desktopUrl?: string;
}

export class newURLConfig {
	public id?: string;
	public name?: string;
	public sourceLink?: string;
	public destination?: string;
	public gotomarket?: UrlType;
	public screenId?: boolean;
	public status?: string;
	public modifications: ModificationDate;
	public included: Array<any>;
	public serviceTypes: Array<any>;
}

export class ModificationDate {
	expiration?: string;
}
