import { environment } from '../../../environments/environment';

export const API_URLS = {
	CONFIGURATIONS: {
		CONFIG_GET: environment.middlewareBaseUrl + 'es/v1/appSettings/settings?osType={type}',
		CONFIG_PATCH: environment.middlewareBaseUrl + 'es/v2/appConfiguration/settings',
	},
	SCHAMAN: {
		GET: environment.middlewareBaseUrl + 'es/v2/appConfiguration/serviceAssuranceConfig',
		PATCH: environment.middlewareBaseUrl + 'es/v2/appConfiguration/serviceAssuranceConfig',
	},
	Login: {
		SESSION_START: environment.middlewareBaseUrl + 'v1/apixoauth2password/oauth2/token',
		// tslint:disable-next-line:max-line-length
		// 'SAML_PROVIDER': `${environment.middlewareBaseUrl}SAML2ServiceProvider/v1/authenticate?entityID=${environment.ENTITY_ID}&client_id=${environment.CLIENT_ID}&scope=ES_ADMIN_CONFIG_ALL%20ES_APP_CONFIGURATION_ALL%20ES_GROUP_SETTINGS_ALL&RelayState={0}/%2F%23%2Flogin`
		SAML_PROVIDER: `${environment.middlewareBaseUrl}SAML2ServiceProvider/v1/authenticate?entityID=${environment.ENTITY_ID}&client_id=${environment.CLIENT_ID}&scope=ES_ADMIN_CONFIG_ALL%20ES_APP_CONFIGURATION_ALL%20ES_ADMIN_DOWNLOAD_ALL%20ES_APP_CONFIGURATION_PAGES_GET%20ES_APP_CONFIGURATION_PAGES_UPDATE%20ES_APP_CONFIGURATION_PAGES_DELETE%20ES_APP_CONFIGURATION_PAGES_CREATE&RelayState={0}/%2F%23%2Flogin`,
	},
	SideMenuConfig: {
		GET_ITEMS: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items',
		GET_SINGLE_ITEM: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items/{id}',
		POST_ITEM: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items',
		PATCH_ITEM: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items/{id}',
		DELETE_ITEM: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items/{id}',
		PATCH_ORDER: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items/order',
	},
	URLSConfig: {
		CONFIG_URL: environment.middlewareBaseUrl + 'es/v2/appConfiguration/urls',
		CONFIG_URL_BY_ID: environment.middlewareBaseUrl + 'es/v2/appConfiguration/urls/{id}',
	},
	AddNewPageDeepLink: {
		URL: environment.middlewareBaseUrl + 'es/v2/appConfiguration/pages',
		URL_BY_ID: environment.middlewareBaseUrl + 'es/v2/appConfiguration/pages/{id}',
	},
	ChatBubbleConfig: {
		GET_ITEMS: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items?config=help',
		POST_ITEM: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items?config=help',
		PATCH_ITEM: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items?config=help',
		DELETE_ITEM: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items/{id}?config=help',
		DOWNLOAD_EXCEL: environment.middlewareBaseUrl + 'es/v2/adminConfig/download/menu/items?config=download',
	},
	AnyDownload: {
		BASE_PATH: environment.middlewareBaseUrl + 'es/v1/adminDownload',
		ExportPaymentInCSV: {
			DOWNLOAD_CSV: '/payments?type={0}&startDate={1}&endDate={2}',
		},
	},
	ExportWalletInfoInCSV: {
		DOWNLOAD_CSV: '/wallet?startSavedDate={0}&endSavedDate={1}&startExpiryDate={2}&endExpiryDate={3}',
	},
	userPermissions: {
		GET_PERMISSIONS: environment.middlewareBaseUrl + 'es/v1/groups',
		POST_PERMISSIONS: environment.middlewareBaseUrl + 'es/v1/groups',
	},
	forceUpdate: {
		GET_FORCE_UPDATE: environment.middlewareBaseUrl + 'es/v2/appConfiguration/apps',
		DELETE_FORCE_UPDATE: environment.middlewareBaseUrl + 'es/v2/appConfiguration/apps/{appId}',
		POST_FORCE_UPDATE: environment.middlewareBaseUrl + 'es/v2/appConfiguration/apps',
		PATCH_FORCE_UPDATE: environment.middlewareBaseUrl + 'es/v2/appConfiguration/apps',
	},
	latestBuilds: {
		GET_LATEST_BUILDS: environment.middlewareBaseUrl + 'es/v1/appSettings/settings?osType={type}',
		PATCH_LATEST_BUILDS: environment.middlewareBaseUrl + 'es/v2/appConfiguration/settings',
	},
	keyValueConfig: {
		GET_ALL: environment.middlewareBaseUrl + 'es/v2/appConfiguration/pages?type=category',
		PATCH_DELETE: environment.middlewareBaseUrl + 'es/v2/appConfiguration/pages/{id}?type=category',
	},
	ENTRYPOINTS: {
		ENTRYPOINTS_GET: environment.middlewareBaseUrl + 'es/v2/appConfiguration/entryPoints',
		ENTRYPOINTS_PATCH: environment.middlewareBaseUrl + 'es/v2/appConfiguration/entryPoints',
	},
};
