<div class="container-fluid admin-panel">
	<div class="row">
		<div class="col-lg-3 col-md-4 col-12 aside-menu">
			<div class="row">
				<div class="col-12">
					<div class="row">
						<div class="panel-group">
							<!-- <div class="panel panel-default ">
                              <div class="panel-heading ">
                                  <h4 class="panel-title ">
                                      <a data-toggle="collapse " data-parent="#accordion " href="#collapseOne "><span class="glyphicon glyphicon-folder-close ">
                                      </span>Content</a>
                                  </h4>
                              </div>
                              <div id="collapseOne " class="panel-collapse collapse in ">
                                  <div class="panel-body ">
                                      <table class="table ">
                                          <tr>
                                              <td>
                                                  <span class="glyphicon glyphicon-pencil text-primary "></span><a href="http://www.jquery2dotnet.com ">Articles</a>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <span class="glyphicon glyphicon-flash text-success "></span><a href="http://www.jquery2dotnet.com ">News</a>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <span class="glyphicon glyphicon-file text-info "></span><a href="http://www.jquery2dotnet.com ">Newsletters</a>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                  <span class="glyphicon glyphicon-comment text-success "></span><a href="http://www.jquery2dotnet.com ">Comments</a>
                                                  <span class="badge ">42</span>
                                              </td>
                                          </tr>
                                      </table>
                                  </div>
                              </div>
                          </div> -->

							<ul #menuItemList class="list-group">
								<li class="list-group-item" *ngIf="getPermissions('configuración general - business')">
									<a class="list-group-item" (click)="goTo('general-config-business')">
										<span class="icon icon-settings"> </span> {{ 'home.menu-item.0' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('configuración general - ao')">
									<a class="list-group-item" (click)="goTo('general-config-ao')">
										<span class="icon icon-settings"> </span> {{ 'home.menu-item.1' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('menu configuración')">
									<a class="list-group-item" (click)="goTo('menu-config')">
										<span class="icon-item">
											<svg
												version="1.1"
												id="Layer_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												width="40px"
												height="40px"
												viewBox="0 0 64 64"
												enable-background="new 0 0 64 64"
												xml:space="preserve"
											>
												<g>
													<defs>
														<rect id="SVGID_1_" width="64" height="64" />
													</defs>
													<clipPath id="SVGID_2_">
														<use xlink:href="#SVGID_1_" overflow="visible" />
													</clipPath>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                              l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                              c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                              c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                              c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                              c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                              c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                              C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                              c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                              c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                              c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                              c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                              c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                              c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z"
													/>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                              c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                              c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                              c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                              c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
													/>
												</g>
											</svg>
										</span>
										{{ 'home.menu-item.3' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('configuración de url')">
									<a class="list-group-item" (click)="goTo('url-config')">
										<span class="icon-item">
											<svg
												version="1.1"
												id="Layer_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												width="40px"
												height="40px"
												viewBox="0 0 64 64"
												enable-background="new 0 0 64 64"
												xml:space="preserve"
											>
												<g>
													<defs>
														<rect id="SVGID_1_" width="64" height="64" />
													</defs>
													<clipPath id="SVGID_2_">
														<use xlink:href="#SVGID_1_" overflow="visible" />
													</clipPath>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                              l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                              c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                              c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                              c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                              c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                              c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                              C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                              c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                              c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                              c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                              c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                              c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                              c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z"
													/>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                              c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                              c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                              c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                              c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
													/>
												</g>
											</svg> </span
										>{{ 'home.menu-item.4' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('chat bubble configuración')">
									<a class="list-group-item" (click)="goTo('chat-config')">
										<span class="icon-item">
											<svg
												version="1.1"
												id="Layer_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												width="40px"
												height="40px"
												viewBox="0 0 64 64"
												enable-background="new 0 0 64 64"
												xml:space="preserve"
											>
												<g>
													<defs>
														<rect id="SVGID_1_" width="64" height="64" />
													</defs>
													<clipPath id="SVGID_2_">
														<use xlink:href="#SVGID_1_" overflow="visible" />
													</clipPath>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                              l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                              c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                              c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                              c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                              c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                              c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                              C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                              c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                              c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                              c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                              c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                              c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                              c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z"
													/>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                              c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                              c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                              c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                              c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
													/>
												</g>
											</svg> </span
										>{{ 'home.chat-config.title' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('gdpr properities')">
									<a class="list-group-item" (click)="goTo('gdpr-properties-config')">
										<span class="icon-item">
											<svg
												version="1.1"
												id="Layer_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												width="40px"
												height="40px"
												viewBox="0 0 64 64"
												enable-background="new 0 0 64 64"
												xml:space="preserve"
											>
												<g>
													<defs>
														<rect id="SVGID_1_" width="64" height="64" />
													</defs>
													<clipPath id="SVGID_2_">
														<use xlink:href="#SVGID_1_" overflow="visible" />
													</clipPath>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                                  l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                                  c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                                  c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                                  c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                                  c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                                  c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                                  C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                                  c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                                  c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                                  c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                                  c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                                  c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                                  c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z"
													/>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                                  c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                                  c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                                  c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                                  c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
													/>
												</g>
											</svg> </span
										>{{ 'home.gdprProperties.title' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('bonita configurations')">
									<a class="list-group-item" (click)="goTo('bonita-config')">
										<span class="icon-item">
											<svg
												version="1.1"
												id="Layer_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												width="40px"
												height="40px"
												viewBox="0 0 64 64"
												enable-background="new 0 0 64 64"
												xml:space="preserve"
											>
												<g>
													<defs>
														<rect id="SVGID_1_" width="64" height="64" />
													</defs>
													<clipPath id="SVGID_2_">
														<use xlink:href="#SVGID_1_" overflow="visible" />
													</clipPath>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                                      l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                                      c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                                      c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                                      c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                                      c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                                      c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                                      C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                                      c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                                      c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                                      c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                                      c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                                      c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                                      c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z"
													/>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                                      c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                                      c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                                      c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                                      c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
													/>
												</g>
											</svg>
										</span>
										{{ 'home.menu-item.5' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('bonita configurations')">
									<a class="list-group-item" (click)="goTo('schaman-config')">
										<span class="icon-item">
											<svg
												version="1.1"
												id="Layer_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												width="40px"
												height="40px"
												viewBox="0 0 64 64"
												enable-background="new 0 0 64 64"
												xml:space="preserve"
											>
												<g>
													<defs>
														<rect id="SVGID_1_" width="64" height="64" />
													</defs>
													<clipPath id="SVGID_2_">
														<use xlink:href="#SVGID_1_" overflow="visible" />
													</clipPath>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                                      l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                                      c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                                      c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                                      c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                                      c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                                      c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                                      C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                                      c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                                      c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                                      c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                                      c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                                      c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                                      c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z"
													/>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                                      c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                                      c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                                      c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                                      c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
													/>
												</g>
											</svg>
										</span>
										{{ 'home.menu-item.14' | translate }}
									</a>
								</li>

								<!-- comment user permission as it is not part of release 5.6 -->

								<!-- <li class="list-group-item " *ngIf="getPermissions('gestión de permisos')">
                                    <a class="list-group-item " (click)="goTo('user-permissions')">
                                                <span class=icon-item>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        width="40px" height="40px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"
                                                        xml:space="preserve">
                                                        <g>
                                                            <defs>
                                                                <rect id="SVGID_1_" width="64" height="64" />
                                                            </defs>
                                                            <clipPath id="SVGID_2_">
                                                                <use xlink:href="#SVGID_1_" overflow="visible" />
                                                            </clipPath>
                                                            <path clip-path="url(#SVGID_2_)" fill="#256e74" d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                                      l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                                      c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                                      c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                                      c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                                      c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                                      c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                                      C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                                      c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                                      c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                                      c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                                      c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                                      c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                                      c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z" />
                                                            <path clip-path="url(#SVGID_2_)" fill="#256e74" d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                                      c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                                      c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                                      c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                                      c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>{{'home.menu-item.6'|translate}}
                                            </a>
                                </li> -->
								<li class="list-group-item" *ngIf="getPermissions('update versions')">
									<a class="list-group-item" (click)="goTo('force-update')">
										<span class="icon icon-sync"></span> {{ 'home.menu-item.7' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('Latest Versions')">
									<a class="list-group-item" (click)="goTo('latest-builds')">
										<span class="icon icon-sync"></span> {{ 'home.menu-item.8' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('Latest Versions')">
									<a class="list-group-item" (click)="goTo('top-up-decsrimination')">
										<span class="icon icon-sync"></span> {{ 'home.menu-item.9' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="isAdminUser">
									<a class="list-group-item" (click)="goTo('payment-csv')">
										<span class="icon icon-download"></span> {{ 'home.menu-item.10' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="true">
									<a class="list-group-item" (click)="goTo('add-new-page-deeplink')">
										<span class="icon icon-sync"></span> {{ 'home.menu-item.11' | translate }}
									</a>
								</li>

								<li class="list-group-item" *ngIf="true">
									<a class="list-group-item" (click)="goTo('product-config')">
										<span class="icon-item">
											<svg
												version="1.1"
												id="Layer_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												x="0px"
												y="0px"
												width="40px"
												height="40px"
												viewBox="0 0 64 64"
												enable-background="new 0 0 64 64"
												xml:space="preserve"
											>
												<g>
													<defs>
														<rect id="SVGID_1_" width="64" height="64" />
													</defs>
													<clipPath id="SVGID_2_">
														<use xlink:href="#SVGID_1_" overflow="visible" />
													</clipPath>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M38.28,58.33H25.71c-0.55,0-1-0.45-1-1v-6.56c-1.98-0.75-3.82-1.8-5.48-3.12
                                              l-5.82,3.28c-0.48,0.27-1.08,0.11-1.36-0.372L5.8,39.77c-0.13-0.23-0.17-0.5-0.1-0.76c0.07-0.26,0.24-0.48,0.47-0.61l5.72-3.25
                                              c-0.33-2.08-0.33-4.19,0-6.27l-5.73-3.32c-0.23-0.13-0.4-0.35-0.47-0.61s-0.03-0.53,0.11-0.76l6.37-10.73
                                              c0.28-0.47,0.89-0.63,1.36-0.35l5.67,3.29c1.66-1.33,3.5-2.38,5.49-3.14V6.67c0-0.55,0.45-1,1-1h12.57c0.55,0,1,0.45,1,1v6.61
                                              c2.02,0.77,3.88,1.85,5.55,3.21l5.77-3.27c0.48-0.27,1.08-0.11,1.36,0.37l6.25,10.79c0.13,0.23,0.17,0.51,0.1,0.76
                                              c-0.07,0.26-0.24,0.48-0.47,0.61L52,29.05c0.32,2.1,0.3,4.22-0.06,6.32l5.76,3.34c0.23,0.13,0.4,0.348,0.47,0.61
                                              c0.07,0.26,0.03,0.53-0.11,0.76l-6.35,10.728c-0.28,0.472-0.89,0.632-1.36,0.362l-5.11-2.96c-1.76,1.34-3.8,2.25-5.96,2.68v6.44
                                              C39.28,57.89,38.83,58.33,38.28,58.33 M26.71,56.33h10.57v-6.29c0-0.5,0.37-0.92,0.86-0.99c2.35-0.32,4.55-1.3,6.37-2.82
                                              c0.32-0.27,0.78-0.31,1.14-0.1l4.85,2.81l5.33-9.01l-5.5-3.19c-0.37-0.22-0.57-0.65-0.48-1.07c0.48-2.3,0.5-4.65,0.07-6.96
                                              c-0.08-0.42,0.12-0.84,0.49-1.05l5.55-3.15l-5.25-9.06l-5.52,3.13c-0.37,0.21-0.83,0.16-1.15-0.12c-1.8-1.57-3.85-2.76-6.11-3.53
                                              c-0.4-0.14-0.67-0.52-0.67-0.95V7.67H26.69v6.28c0,0.43-0.27,0.81-0.68,0.95c-2.23,0.76-4.27,1.92-6.06,3.46
                                              c-0.32,0.28-0.79,0.32-1.15,0.11l-5.41-3.14l-5.35,9.01l5.46,3.16c0.37,0.21,0.56,0.64,0.48,1.06c-0.45,2.3-0.45,4.62,0,6.92
                                              c0.08,0.42-0.11,0.85-0.49,1.06l-5.46,3.1l5.25,9.07l5.56-3.13c0.37-0.21,0.82-0.16,1.14,0.11c1.78,1.53,3.82,2.69,6.04,3.44
                                              c0.41,0.14,0.68,0.52,0.68,0.95v6.25H26.71z"
													/>
													<path
														clip-path="url(#SVGID_2_)"
														fill="#256e74"
														d="M31.98,42.33c-2.51,0-4.9-0.9-6.8-2.57c-2.07-1.818-3.32-4.34-3.5-7.09
                                              c-0.37-5.69,3.95-10.61,9.64-10.98c0.45-0.03,0.9-0.03,1.34,0c5.16,0.34,9.3,4.48,9.64,9.64c0.18,2.75-0.72,5.41-2.55,7.49
                                              c-1.82,2.07-4.34,3.32-7.09,3.5C32.44,42.33,32.21,42.33,31.98,42.33 M32,23.67c-0.18,0-0.36,0.01-0.54,0.02
                                              c-4.58,0.3-8.07,4.27-7.77,8.86c0.14,2.22,1.15,4.25,2.82,5.72c1.67,1.47,3.82,2.2,6.04,2.05c2.22-0.14,4.25-1.15,5.72-2.82
                                              c1.47-1.67,2.2-3.82,2.05-6.04c-0.27-4.16-3.61-7.5-7.77-7.77C32.36,23.67,32.18,23.67,32,23.67"
													/>
												</g>
											</svg>
										</span>
										{{ 'home.menu-item.12' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="isAdminUser">
									<a class="list-group-item" (click)="goTo('chat-module-page-config')">
										<span class="icon icon-sync"></span> {{ 'home.menu-item.13' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="isAdminUser">
									<a class="list-group-item" (click)="goTo('wallet-info')">
										<span class="icon icon-download"></span> {{ 'home.menu-item.15' | translate }}
									</a>
								</li>
								<li class="list-group-item" *ngIf="getPermissions('configuración flujos tx - ao')">
									<a class="list-group-item" (click)="goTo('flows-config-ao')">
										<span class="icon icon-settings"> </span> Configuración Flujos TX - AO
									</a>
								</li>
								<li class="list-group-item" *ngIf="true">
									<a class="list-group-item" (click)="goTo('update-eventconfiguration')">
										<span class="icon icon-sync"></span> {{ 'home.menu-item.16' | translate }}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-9 col-md-8 col-12 admin-wrapper home-body">
			<div class="custom-container">
				<router-outlet></router-outlet>
			</div>
			<div [ngStyle]="{ height: menuItemList.childElementCount * 16 + 'px' }" class="clearfix"></div>
			<div class="admin-footer">
				<sp-footer></sp-footer>
			</div>
		</div>
	</div>
</div>
