export interface ISideMenuItem {
	id?: string;
	parentId?: string;
	name?: string;
	title?: string;
	enabled?: boolean;
	description?: string;
	iconName?: string;
	category?: string;
	order?: number;
	apps?: App[];
	navigation?: {
		type?: string;
		path?: string;
		profile?: string;
	};
	account?: {
		status?: string[];
		accountTypes?: AccountType[];
	};
	segments?: string[];
	subItems?: ISideMenuItem[];
}

export interface MenuItemsGroup {
	AccountItems: ISideMenuItem[];
	ServiceItems: ISideMenuItem[];
	GeneralItems: ISideMenuItem[];
}

export interface ServiceType {
	type?: string;
	status?: string;
}

export interface App {
	platform?: string;
	from?: string;
	to?: string;
}

export class AccountType {
	type?: string;
	serviceTypes?: ServiceType[];
	constructor(type: string) {
		this.type = type;
		this.serviceTypes = new Array<ServiceType>();
	}
}
