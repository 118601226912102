import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Routes from '../constants/routes-config';
import { JSON_PATHS } from '../constants/defines';
import * as jsonQuery from 'jsonpath';
import { HttpHeaders } from '@angular/common/http';
import { KeyValueConfig } from '../../models/chat-modules-pages-config.model';

@Injectable()
export class KeyValueConfigService {
	allData: KeyValueConfig[];
	constructor(private http: HttpClient) {}
	getAllKeyValueConfigs() {
		this.allData = [];
		return this.http.get(Routes.API_URLS.keyValueConfig.GET_ALL).pipe(
			map((response: any) => {
				const categories = jsonQuery.value(response, JSON_PATHS.KEYVALUECONFIG.CATEGORIES);
				if (categories) {
					categories.forEach((res) => {
						const category = new KeyValueConfig();
						category.id = jsonQuery.value(res, JSON_PATHS.KEYVALUECONFIG.ID) || '';
						category.name = jsonQuery.value(res, JSON_PATHS.KEYVALUECONFIG.NAME) || '';
						category.key = jsonQuery.value(res, JSON_PATHS.KEYVALUECONFIG.KEY) || '';
						category.value = jsonQuery.value(res, JSON_PATHS.KEYVALUECONFIG.VALUE) || '';
						this.allData.push(category);
					});
				}
			})
		);
	}

	addCategory(item: KeyValueConfig) {
		let headers = new HttpHeaders();
		headers = headers.append('content-type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers,
		};
		return this.http.post(
			Routes.API_URLS.keyValueConfig.GET_ALL,
			{ categories: [item] },
			{ ...options, responseType: 'text' }
		);
	}

	updateCategory(item: KeyValueConfig) {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('Accept', 'application/json');
		const options = {
			headers: headers,
		};
		const category = Routes.API_URLS.keyValueConfig.PATCH_DELETE.replace('{id}', item.id);
		return this.http.patch(category, item, options);
	}

	deleteCategory(id: string) {
		const url = Routes.API_URLS.keyValueConfig.PATCH_DELETE.replace('{id}', id);
		return this.http.delete(url);
	}
}
