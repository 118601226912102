import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationService } from '../../common/services/configuration.service';
import { patterns } from '../../common/constants/defines';
import { TopUpDiscriminationModel } from '../../models/top-up-discrimination.model';
import { Notification } from '../../models/notification.model';
import { NotificationService } from '../../common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'sp-top-up-descrimination-config',
	templateUrl: './top-up-descrimination-config.component.html',
	styleUrls: ['./top-up-descrimination-config.component.scss'],
})
export class TopUpDescriminationConfigComponent implements OnInit {
	constructor(
		private _fb: FormBuilder,
		public configurationService: ConfigurationService,
		private notificationService: NotificationService,
		private translateService: TranslateService
	) {
		this.form = _fb.group({ msidnPattern: [''], smartpayMsidnPattern: [''] });
	}

	ngOnInit() {
		this.configureTopUpDiscriminations();
	}
	form: FormGroup;
	isAboveNineCharacters = false;
	patternArray: any = [];
	isWrongPatternFormat = false;
	typedPatternWithoutCommas = '';
	topUpDiscriminationConfigurations: TopUpDiscriminationModel;
	originaltopUpDiscriminationConfigurations: TopUpDiscriminationModel;
	shouldPatternStartWithSixOrSeven = false;

	checkmsidnPattern(event) {
		this.resetFlags();
		const typedPatternValue = event.target.value;
		const regex1 = new RegExp(patterns.numbersAndCommasOnly);
		if (typedPatternValue) {
			if (!regex1.test(typedPatternValue)) {
				this.isWrongPatternFormat = true;
			} else {
				const patternArray = event.target.value.split(',');
				this.checkForPatternsHavingMoreThenNineCharacters(patternArray);
				this.checkForPatternsStartingWithSixOrSeven(patternArray);
				this.checkIfTypedMsidnPatternHasSpaces(patternArray);
			}
		}
	}
	checkmsidnSmartpayPattern(event: Event & { target: HTMLButtonElement }): void {
		this.checkmsidnPattern(event);

		if (this.isWrongPatternFormat === true && event.target.value === '-1') {
			this.isWrongPatternFormat = false;
		}
	}
	checkForPatternsStartingWithSixOrSeven(patternArray) {
		const completePatterns = patternArray.filter((fullPattern) => {
			return fullPattern.length === 9;
		});
		if (completePatterns.length > 0) {
			completePatterns.forEach((element) => {
				if (element[0] !== '6' && element[0] !== '7') {
					this.shouldPatternStartWithSixOrSeven = true;
				}
			});
		}
	}
	checkForPatternsHavingMoreThenNineCharacters(patternArray) {
		const filteredPatterns = patternArray.filter((typedPattern) => {
			return typedPattern.length > 9;
		});
		if (filteredPatterns.length > 0) {
			this.isAboveNineCharacters = true;
		}
	}
	checkIfTypedMsidnPatternHasSpaces(patternArray) {
		patternArray.forEach((elem) => {
			if (elem.split(' ').join('') === '') {
				this.isWrongPatternFormat = true;
			}
		});
	}
	resetFlags() {
		this.isAboveNineCharacters = false;
		this.isWrongPatternFormat = false;
		this.shouldPatternStartWithSixOrSeven = false;
	}
	configureTopUpDiscriminations() {
		this.topUpDiscriminationConfigurations = this.configurationService.getTopUpDiscriminationConfig();
		this.originaltopUpDiscriminationConfigurations = JSON.parse(JSON.stringify(this.topUpDiscriminationConfigurations));
		this.form.controls['msidnPattern'].setValue(this.topUpDiscriminationConfigurations.topupNewPCIMsisdnPattern);
		this.form.controls['smartpayMsidnPattern'].setValue(
			this.topUpDiscriminationConfigurations.smartPayMSIDNPattern != '-1'
				? this.topUpDiscriminationConfigurations.smartPayMSIDNPattern
				: ''
		);
	}
	updateTopUpDiscrimination() {
		if (
			(!this.isWrongPatternFormat && !this.isAboveNineCharacters && !this.shouldPatternStartWithSixOrSeven) ||
			!this.topUpDiscriminationConfigurations.topupNewPCI
		) {
			if (this.topUpDiscriminationConfigurations.topupNewPCI) {
				this.topUpDiscriminationConfigurations.topupNewPCIMsisdnPattern = this.form.controls['msidnPattern'].value;
				this.topUpDiscriminationConfigurations.smartPayMSIDNPattern = this.form.controls['smartpayMsidnPattern'].value;
			} else {
				this.topUpDiscriminationConfigurations.topupNewPCIMsisdnPattern =
					this.originaltopUpDiscriminationConfigurations.topupNewPCIMsisdnPattern;
				this.topUpDiscriminationConfigurations.smartPayMSIDNPattern =
					this.originaltopUpDiscriminationConfigurations.smartPayMSIDNPattern;
			}

			this.configurationService.patchConfiguration(this.topUpDiscriminationConfigurations).subscribe(
				(res) => {
					this.originaltopUpDiscriminationConfigurations = this.topUpDiscriminationConfigurations;
					const form = this;
					const notification = new Notification();
					notification.primaryButtonClick = function () {
						form.notificationService.notificationModal.hide();
					};
					this.translateService.get('home.messages').subscribe((data) => {
						notification.bodyContent = data['save-configuration'];
						notification.primaryButtonText = data['buttons']['close'];
						notification.bodyTitle = data['update-configuration'];
					});
					notification.primaryButtonStyle = 'btn registration';
					this.notificationService.createNotification(notification);
				},
				(error) => {
					const notification = new Notification();
					const modal = this;
					this.translateService.get('home.messages').subscribe((data) => {
						notification.bodyContent = data['error-text'];
						notification.primaryButtonText = data['buttons']['sure'];
					});
					notification.primaryButtonStyle = 'btn registration';
					notification.primaryButtonClick = function () {
						modal.notificationService.notificationModal.hide();
					};
					this.notificationService.createNotification(notification);
				}
			);
		}
	}
	flagCheckedChanged(event) {
		this.topUpDiscriminationConfigurations.topupNewPCI = event;
	}
}
