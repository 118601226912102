<div
	bsModal
	#modal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="mySmallModalLabel"
	aria-hidden="true"
	[config]="{ keyboard: false, backdrop: 'static' }"
>
	<div class="side-menu modal-dialog" style="padding-top: 5%">
		<div class="modal-content">
			<form [formGroup]="form" novalidate>
				<fieldset>
					<span class="title">{{ title }}</span>
					<div class="modal-body">
						<div class="body">
							<div class="control-container">
								<div class="row">
									<sp-text
										formControlName="itemName"
										label="{{ 'home.menu-config.page-two.field-set.section1.0' | translate }}"
										[isRequired]="true"
									></sp-text>
								</div>

								<div class="row">
									<sp-text
										formControlName="technicalName"
										(keypress)="omit_special_char($event.charCode)"
										(paste)="onPaste($event)"
										(drop)="onDrop($event)"
										label="{{ 'home.menu-config.page-two.field-set.section1.1' | translate }}"
										[isRequired]="true"
									></sp-text>
								</div>

								<div class="row">
									<sp-icon-auto-complete
										#iconInput
										[query]="selectedIcon"
										label="{{ 'home.menu-config.page-two.field-set.section1.3' | translate }}"
										(onSelect)="selectedIcon = $event"
									></sp-icon-auto-complete>
								</div>
								<div class="row text-area">
									<label>{{ 'home.menu-config.page-two.field-set.section1.2' | translate }}</label>
									<textarea rows="4" cols="50" [(ngModel)]="description" [ngModelOptions]="{ standalone: true }">
									</textarea>
								</div>
								<div class="row">
									<div class="col-lg-5 col-md-6 col-12">
										<div class="row">
											<sp-dropdown
												[selectedValue]="selectedDirection"
												(change)="directToChanged($event.target.value)"
												label="{{ 'home.menu-config.page-two.field-set.section1.4' | translate }}"
												[items]="directToList"
											>
											</sp-dropdown>
										</div>
									</div>
									<div *ngIf="!isContengency" class="col-lg-7 col-md-6 col-12 cutom url-text">
										<div class="row">
											<sp-dropdown
												[ngStyle]="{ display: !isExternal && !isOldPortal && !isECare ? 'inherit' : 'none' }"
												[selectedValue]="internalPath"
												(change)="internalPath = $event.target.value"
												[items]="internalRoutesList"
											>
											</sp-dropdown>
											<sp-text
												[ngStyle]="{ display: isExternal || isOldPortal || isECare ? 'inherit' : 'none' }"
												class="text-box"
												formControlName="externalPath"
												[label]="' '"
												[isRequired]="true"
											></sp-text>
										</div>
									</div>
								</div>
								<div>
									<div class="row light-checkbox">
										<sp-check-box
											[name]="'checkbox'"
											label="{{ 'home.menu-config.page-two.field-set.section1.5' | translate }}"
											[check]="isLight"
											(option)="isLight = $event"
										></sp-check-box>
									</div>
								</div>
								<hr />
								<div class="row section-2">
									<div class="row needed">
										<div class="col-12 col-md-6 col-lg-4">
											<div class="row">
												<!-- if item.parent then this is the child item, so disable this drop down list (group) -->
												<sp-dropdown
													[selectedValue]="selectedGroup"
													(change)="selectedGroup = $event.target.value"
													label="{{ 'home.menu-config.page-two.field-set.section2.0' | translate }}"
													[items]="groupsList"
													[isDisabled]="content?.parentId"
												>
												</sp-dropdown>
											</div>
										</div>
										<div class="col-12 col-md-6 col-lg-4">
											<angular2-multiselect
												class="multiselect"
												[data]="siteStatusList"
												label="{{ 'home.menu-config.page-two.field-set.section2.1' | translate }}"
												(onSelect)="onItemSelect($event, 'siteStatus')"
												(onDeSelect)="onItemDeSelect($event, 'siteStatus')"
												[selectedItems]="siteStatusListSelected"
												(onSelectAll)="onSelectAll($event, 'siteStatus')"
												(onDeSelectAll)="onDeSelectAll($event, 'siteStatus')"
											></angular2-multiselect>
										</div>

										<div class="col-12 col-md-6 col-lg-4">
											<angular2-multiselect
												class="multiselect"
												[data]="customerSegmentList"
												label="{{ 'home.menu-config.page-two.field-set.section2.2' | translate }}"
												(onSelect)="onItemSelect($event, 'customerSegment')"
												(onDeSelect)="onItemDeSelect($event, 'customerSegment')"
												[selectedItems]="customerSegmentListSelected"
												(onSelectAll)="onSelectAll($event, 'customerSegment')"
												(onDeSelectAll)="onDeSelectAll($event, 'customerSegment')"
											></angular2-multiselect>
										</div>
									</div>
								</div>
								<div class="section-3" *ngIf="showServicesSection">
									<span>{{ 'home.menu-config.page-two.field-set.section3.0' | translate }}</span>
									<div class="dropdown-groups">
										<div class="col-12 col-md-6 col-lg-6">
											<angular2-multiselect
												class="multiselect"
												[data]="consumerList"
												label="{{ 'home.menu-config.page-two.field-set.section3.1' | translate }}"
												(onSelect)="onItemSelect($event, 'consumer')"
												(onDeSelect)="onItemDeSelect($event, 'consumer')"
												[selectedItems]="consumerListSelected"
												(onSelectAll)="onSelectAll($event, 'consumer')"
												(onDeSelectAll)="onDeSelectAll($event, 'consumer')"
											></angular2-multiselect>
										</div>
										<div class="col-12 col-md-6 col-lg-6">
											<angular2-multiselect
												class="multiselect"
												[data]="authorizedList"
												label="{{ 'home.menu-config.page-two.field-set.section3.2' | translate }}"
												(onSelect)="onItemSelect($event, 'authorized')"
												(onDeSelect)="onItemDeSelect($event, 'authorized')"
												[selectedItems]="authorizedListSelected"
												(onSelectAll)="onSelectAll($event, 'authorized')"
												(onDeSelectAll)="onDeSelectAll($event, 'authorized')"
											></angular2-multiselect>
										</div>
										<div class="col-12 col-md-6 col-lg-6">
											<angular2-multiselect
												class="multiselect"
												[data]="employeeList"
												label="{{ 'home.menu-config.page-two.field-set.section3.3' | translate }}"
												(onSelect)="onItemSelect($event, 'employee')"
												(onDeSelect)="onItemDeSelect($event, 'employee')"
												[selectedItems]="employeeListSelected"
												(onSelectAll)="onSelectAll($event, 'employee')"
												(onDeSelectAll)="onDeSelectAll($event, 'employee')"
											></angular2-multiselect>
										</div>
										<div class="col-12 col-md-6 col-lg-6">
											<angular2-multiselect
												class="multiselect"
												[data]="employeesmeList"
												label="{{ 'home.menu-config.page-two.field-set.section3.4' | translate }}"
												(onSelect)="onItemSelect($event, 'employee sme')"
												(onDeSelect)="onItemDeSelect($event, 'employee sme')"
												[selectedItems]="employeeSMEListSelected"
												(onSelectAll)="onSelectAll($event, 'employee sme')"
												(onDeSelectAll)="onDeSelectAll($event, 'employee sme')"
											></angular2-multiselect>
										</div>
									</div>
								</div>
								<div class="row section-4">
									<div class="col-12">
										<angular2-multiselect
											class="multiselect"
											[data]="platformList"
											label="{{ 'home.menu-config.page-two.field-set.section4.0' | translate }}"
											(onSelect)="onItemSelect($event, 'platform')"
											(onDeSelect)="onItemDeSelect($event, 'platform')"
											[selectedItems]="platformListSelected"
											(onSelectAll)="onSelectAll($event, 'platform')"
											(onDeSelectAll)="onDeSelectAll($event, 'platform')"
										></angular2-multiselect>
									</div>
								</div>
								<div class="row multi-grids">
									<div class="col-md-6 col-12">
										<div class="row">
											<div class="col-md-6 col-12 col-lg-4">
												<div class="row">
													<sp-text
														textType="number"
														(input)="omit_char($event)"
														formControlName="fromVersionFirst"
														label="{{ 'home.menu-config.page-two.field-set.section4.1' | translate }}"
														[isRequired]="true"
													></sp-text>
												</div>
											</div>
											<div class="col-md-6 col-12 col-lg-4">
												<div class="row">
													<sp-text
														textType="number"
														(input)="omit_char($event)"
														preventkeys="[69,189]"
														formControlName="fromVersionSecond"
														[label]="' '"
														[isRequired]="true"
													></sp-text>
												</div>
											</div>
											<div class="col-md-6 col-12 col-lg-4">
												<div class="row">
													<sp-text
														textType="number"
														(input)="omit_char($event)"
														preventkeys="[69,189]"
														formControlName="fromVersionThird"
														[label]="' '"
														[isRequired]="true"
													></sp-text>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-12">
										<div class="row">
											<div class="col-md-6 col-12 col-lg-4">
												<div class="row">
													<sp-text
														textType="number"
														(input)="omit_char($event)"
														preventkeys="[69,189]"
														formControlName="toVersionFirst"
														label="{{ 'home.menu-config.page-two.field-set.section4.2' | translate }}"
														[isRequired]="true"
													></sp-text>
												</div>
											</div>
											<div class="col-md-6 col-12 col-lg-4">
												<div class="row">
													<sp-text
														textType="number"
														(input)="omit_char($event)"
														preventkeys="[69,189]"
														formControlName="toVersionSecond"
														[label]="' '"
														[isRequired]="true"
													></sp-text>
												</div>
											</div>
											<div class="col-md-6 col-12 col-lg-4">
												<div class="row">
													<sp-text
														textType="number"
														(input)="omit_char($event)"
														preventkeys="[69,189]"
														formControlName="toVersionThird"
														[label]="' '"
														[isRequired]="true"
													></sp-text>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />
								<div class="row section-5">
									<span>{{ 'home.menu-config.page-two.field-set.section5.0' | translate }}</span>
									<sp-toggle-button
										class="toggle-button"
										_ngcontent-c11
										[check]="isEnabled"
										(option)="isEnabled = $event"
									></sp-toggle-button>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
				<div class="buttons-form">
					<div class="form-group form-group col-md-4 offset-md-3 col-12">
						<button (click)="hideModal()" class="button gray two">
							{{ 'home.menu-config.page-two.buttons.cancel' | translate }}
						</button>
					</div>
					<div class="form-group col-md-4 col-12">
						<button
							type="button"
							*ngIf="!isEdit"
							class="button"
							[ngClass]="{ 'red two': isValid }"
							[disabled]="!isValid || isAddedOnce"
							(click)="submit(form)"
						>
							{{ 'home.menu-config.page-two.buttons.add' | translate }}
						</button>
						<button
							type="button"
							*ngIf="isEdit"
							class="button"
							[ngClass]="{ 'red two': isValid }"
							[disabled]="!isValid"
							(click)="submit(form)"
						>
							{{ 'home.menu-config.page-two.buttons.edit' | translate }}
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
