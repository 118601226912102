import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Routes from '../../common/constants/routes-config';
import { ForceType } from '../../common/enums/force-update.enum';
import { Notification } from '../../models/notification.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../common/services/notification.service';

@Injectable()
export class ForceUpdateService {
	constructor(
		private http: HttpClient,
		private translateService: TranslateService,
		private notificationService: NotificationService
	) {}

	getGridData() {
		return this.http.get(Routes.API_URLS.forceUpdate.GET_FORCE_UPDATE).pipe(
			map((res: any) => {
				res.apps.map((element) => {
					element.force = element.force ? ForceType.Enforced : ForceType.Optional;
					if (element.date) {
						element.date.creation = new Date(element.date.creation).toLocaleDateString('es');
						element.date.expiry = new Date(element.date.expiry).toLocaleDateString('es');
						element.date.modification = new Date(element.date.modification).toLocaleDateString('es');
					}
					return element;
				});
				return res.apps;
			})
		);
	}

	deleteBuildData(build) {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		const url = Routes.API_URLS.forceUpdate.DELETE_FORCE_UPDATE.replace('{appId}', build.id.toString());
		return this.http.delete(url, options);
	}

	addBuildData(form) {
		let headers = new HttpHeaders();
		headers = headers.append('content-type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers: headers,
		};
		const body = form;
		return this.http.post(Routes.API_URLS.forceUpdate.POST_FORCE_UPDATE, body, options);
	}

	updateBuildData(form) {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('Accept', 'application/json');
		const options = {
			headers: headers,
		};
		const body = form;
		return this.http.patch(Routes.API_URLS.forceUpdate.PATCH_FORCE_UPDATE, body, options);
	}

	createForceUpdateError(errorKeyName: string) {
		const notification = new Notification();
		const modal = this;
		this.translateService.get('home.messages').subscribe((data) => {
			notification.bodyContent = data[errorKeyName];
			notification.primaryButtonText = data['buttons']['cancel'];
		});
		notification.primaryButtonStyle = 'btn registration';
		notification.primaryButtonClick = function () {
			modal.notificationService.notificationModal.hide();
		};
		this.notificationService.createNotification(notification);
	}
}
