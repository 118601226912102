import { LogedinGuard } from './common/authentication/logedin.guard';
/** this file includes all routes of our project */
import { config } from '../config/pages-config';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpLoginComponent } from './sp-login/sp-login.component';
import { AuthGuard } from './common/authentication/auth.guard';
import { resolve } from 'url';
import { GeneralConfigResolver } from './home/general-config-business/general-config-business.resolver';
import { ReloadGuard } from './common/guards/reload.guard';
import { HomeModule } from './home/home.module';

const routes: Routes = [
	{
		path: '',
		redirectTo: config.login.name,
		pathMatch: 'full',
	},
	{
		path: config.home.name,
		// loadChildren: config.home.loadChildren,
		loadChildren: () => HomeModule,
		canActivate: [AuthGuard],
		resolve: {
			subscription: GeneralConfigResolver,
		},
	},
	{
		path: config.login.name,
		component: SpLoginComponent,
		canActivate: [LogedinGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
