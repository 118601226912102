import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateService } from './authenticate.service';
import { config } from '../../../config/pages-config';
/**
 * auth guard to check if the user loged in redirect user to home
 */
@Injectable()
export class LogedinGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthenticateService) {}
	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		if (this.checkIfLoggedInUser()) {
			this.router.navigate([config.home.name]);
			return false;
		} else {
			return true;
		}
	}
	private checkIfLoggedInUser() {
		return (
			(this.authService.isAccessTokenExist() && !this.authService.isAccessTokenExpired()) ||
			(this.authService.isRefreshTokenExist() && !this.authService.isRefreshTokenExpired())
		);
	}
}
