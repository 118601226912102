<div class="admin-top-title">
	<br />
	<p>{{ 'home.chat-modules-pages-configuration.title' | translate }}</p>
	<hr />
</div>
<div class="url-config">
	<div *ngIf="showError">
		<div class="error-url-modal col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
			<span class="icon-warning"></span
			>{{ 'home.chat-modules-pages-configuration.page-one.duplicatedValidation' | translate }}
		</div>
	</div>
	<form [formGroup]="configsForm">
		<div class="row">
			<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">{{ 'home.chat-modules-pages-configuration.page-one.category' | translate }}:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-dropdown
							class="input-bg"
							[selectedValue]="selectedCategory"
							(change)="changeCategory($event.target.value)"
							[items]="categoriesList"
						>
						</sp-dropdown>
					</div>
				</div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">{{ 'home.chat-modules-pages-configuration.page-one.key' | translate }}:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text formControlName="key" class="input-bg"></sp-text>
					</div>
				</div>
				<div class="admin-top row">
					<div class="col-md-4 col-12 title-tag">
						<p class="title">{{ 'home.chat-modules-pages-configuration.page-one.value' | translate }}:</p>
					</div>
					<div class="col-md-8 col-12 padding-r-0">
						<sp-text formControlName="value" class="input-bg"></sp-text>
					</div>
				</div>
			</div>
			<div class="configuration-btn offset-md-1 col-12">
				<div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
					<button
						class="blue-add"
						[disabled]="configsForm.invalid"
						[ngClass]="{ 'gray-add': configsForm.invalid, 'blue-add': !configsForm.invalid }"
						(click)="addCategory()"
						type="submit"
					>
						{{ 'home.chat-modules-pages-configuration.page-one.buttons.add' | translate }}
					</button>
				</div>
			</div>
		</div>
	</form>
</div>

<hr />
<div *ngIf="currentCategories && !currentCategories.length" class="loader"></div>
<div class="row" *ngIf="currentCategories && currentCategories.length > 0">
	<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container table-urls">
		<div class="search-container">
			<sp-text class="search-url" [(ngModel)]="filterKey"></sp-text>
			<span class="icon icon-search"></span>
			<div class="clearfix"></div>
		</div>
		<div *ngFor="let category of currentCategories | search : 'key' : filterKey">
			<div class="url-container url-container-edit">
				<div class="row url-desc" *ngIf="category.category">
					<div class="col-4">{{ 'home.chat-modules-pages-configuration.page-one.category' | translate }}</div>
					<div class="col-8 pl-0">{{ category.category }}</div>
				</div>
				<div class="row url-desc" *ngIf="category.key">
					<div class="col-4">{{ 'home.chat-modules-pages-configuration.page-one.key' | translate }}</div>
					<div class="col-8 pl-0">{{ category.key }}</div>
				</div>

				<div class="row url-desc" *ngIf="category.value">
					<div class="col-4">{{ 'home.chat-modules-pages-configuration.page-one.value' | translate }}</div>
					<div class="col-8 pl-0">{{ category.value }}</div>
				</div>

				<div class="row url-desc url-desc-btn-container">
					<button class="Delete-btn" (click)="deleteCategory(category)">
						{{ 'home.chat-modules-pages-configuration.page-one.buttons.delete' | translate }}
					</button>
					<button class="Edit-btn" (click)="editView(category)">
						{{ 'home.chat-modules-pages-configuration.page-one.buttons.edit' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div
	bsModal
	#modal="bs-modal"
	class="modal fade"
	tabindex="-1"
	role="dialog"
	aria-labelledby="mySmallModalLabel"
	aria-hidden="true"
	[config]="{ keyboard: false, backdrop: 'static' }"
>
	<div class="small modal-dialog allow-events">
		<div class="modal-content">
			<div class="modal-header" *ngIf="showCloseIcon">
				<button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
					<span class="icon icon-close modal-close"></span>
				</button>
			</div>
		</div>
		<div class="url-config">
			<div class="modal-body modal-edit toggle-container">
				<div>
					<div class="row title-edit center-row">
						<div class="col-10">
							{{ 'home.chat-modules-pages-configuration.page-two.title' | translate }}
						</div>
						<div class="close-edit col-2" (click)="hide()">
							<span class="remove-parameter">
								<i class="icon icon-close"></i>
							</span>
						</div>

						<div class="clearfix"></div>
					</div>
					<div *ngIf="showError">
						<div class="error-url-modal col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
							<span class="icon-warning"></span>{{ 'home.url-config.page-one.duplicatedValidation' | translate }}
						</div>
					</div>
					<div class="row">
						<div class="description-form col-12">
							<form [formGroup]="configsFormEdit">
								<div class="row">
									<div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
										<div class="admin-top row">
											<div class="col-md-4 col-12 title-tag">
												<p class="title">
													{{ 'home.chat-modules-pages-configuration.page-one.category' | translate }}:
												</p>
											</div>
											<div class="col-md-8 col-12 padding-r-0">
												<sp-dropdown
													class="input-bg"
													[selectedValue]="selectedCategoryEdit"
													(change)="selectedCategoryEdit = $event.target.value"
													[items]="categoriesList"
												>
												</sp-dropdown>
											</div>
										</div>
										<div class="admin-top row">
											<div class="col-md-4 col-12 title-tag">
												<p class="title">{{ 'home.chat-modules-pages-configuration.page-one.key' | translate }}:</p>
											</div>
											<div class="col-md-8 col-12 padding-r-0">
												<sp-text formControlName="key" class="input-bg"></sp-text>
											</div>
										</div>
										<div class="admin-top row">
											<div class="col-md-4 col-12 title-tag">
												<p class="title">{{ 'home.chat-modules-pages-configuration.page-one.value' | translate }}:</p>
											</div>
											<div class="col-md-8 col-12 padding-r-0">
												<sp-text formControlName="value" class="input-bg"></sp-text>
											</div>
										</div>
									</div>
									<div class="configuration-btn offset-md-1 col-12">
										<div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
											<button
												class="blue-add"
												(click)="updateCategory()"
												[disabled]="configsFormEdit.invalid"
												[ngClass]="{ 'gray-add': configsFormEdit.invalid, 'blue-add': !configsFormEdit.invalid }"
												type="submit"
											>
												{{ 'home.chat-modules-pages-configuration.page-one.buttons.editCategory' | translate }}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
