import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DeepLinkNewPage } from '../../models/add-new-page-deepLink.model';
import { HttpClient } from '@angular/common/http';
import * as Routes from '../constants/routes-config';
import { JSON_PATHS } from '../constants/defines';
import * as jsonQuery from 'jsonpath';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageItem } from '../../../app/models/pageItem.model';

@Injectable()
export class AddNewPageDeepLinkService {
	pages: DeepLinkNewPage[];
	constructor(private http: HttpClient) {}
	getAllURLS(): Observable<void> {
		this.pages = [];
		return this.http.get(Routes.API_URLS.AddNewPageDeepLink.URL).pipe(
			map((response: any[]) => {
				const pages = jsonQuery.value(response, JSON_PATHS.ADDNEWPAGEDEEPLINK.PAGES);
				if (pages) {
					pages.forEach((res) => {
						const url = new DeepLinkNewPage();
						url.id = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.ID) || '';
						url.name = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.NAME) || '';
						url.tagName = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.TAGNAME) || '';
						url.sourceLink = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.SOURCELINK) || '';
						url.nativeUrl = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.NATIVEURL) || '';
						url.destination = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.DESTINATION) || '';
						url.desktopUrl = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.DESKTOPURL) || '';
						url.webUrl = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.WEBURL) || '';
						url.serviceTypes = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.SERVICETYPES) || [];
						url.hideFromDeepLink = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.HIDEFROMDEEPLINK) || false; // default false
						url.included = jsonQuery.value(res, JSON_PATHS.ADDNEWPAGEDEEPLINK.INCLUDED) || []; // default false
						this.pages.push(url);
					});
				}
			})
		);
	}

	addURL(item: PageItem): Observable<string> {
		let headers = new HttpHeaders();
		let URL: string = Routes.API_URLS.AddNewPageDeepLink.URL + '?type=deeplinkPage';
		headers = headers.append('content-type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers,
		};
		return this.http.post(URL, item, { ...options, responseType: 'text' });
	}

	updateURL(body: DeepLinkNewPage) {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('Accept', 'application/json');
		const options = {
			headers: headers,
		};
		const url = Routes.API_URLS.AddNewPageDeepLink.URL_BY_ID.replace('{id}', body.id);
		return this.http.patch(url, body, options);
	}

	deleteURL(id) {
		// no headers needed
		const url = Routes.API_URLS.AddNewPageDeepLink.URL_BY_ID.replace('{id}', id);
		return this.http.delete(url);
	}
}
