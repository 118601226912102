import { NotificationService } from './../../common/services/notification.service';
import { IDropdown } from './../../common/components/sp-dropdown/IDropdown.interface';
import { MenuItemsGroup, ISideMenuItem } from './../../models/sidemenuItem.model';
import { SidemenuConfigService } from './../../common/services/sidemenu-config.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SidemenuNewItemComponent } from '../../common/components/sidemenu-new-item/sidemenu-new-item.component';
import { sortBy } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../../models/notification.model';
import * as pageConfig from '../../../config/pages-config';

@Component({
	selector: 'sp-menu-config',
	templateUrl: './menu-config.component.html',
	styleUrls: ['./menu-config.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MenuConfigComponent implements OnInit {
	@ViewChild('notification', { static: true }) notification: SidemenuNewItemComponent;
	public currentItem: ISideMenuItem = null;
	public accountItems: ISideMenuItem[];
	public serviceItems: ISideMenuItem[];
	public generalItems: ISideMenuItem[];
	public isEdit = false;
	public consumerList: IDropdown[];
	public employeeList: IDropdown[];
	public authorizedList: IDropdown[];
	public employeeSMEList: IDropdown[];
	public accountTypeList: IDropdown[];
	public filteredServiceList: IDropdown[];
	public selectedType: string;
	public selectedService = '';
	public left = false;
	public right = false;

	form: FormGroup;
	constructor(
		private sidemenuConfigService: SidemenuConfigService,
		private _fb: FormBuilder,
		private translateService: TranslateService,
		private notificationService: NotificationService
	) {
		this.form = _fb.group({
			menuItem: '',
		});
	}

	ngOnInit() {
		this.accountTypeList = pageConfig.config.accountTypeList;
		this.consumerList = pageConfig.config.menuConfigConsumerList;
		this.employeeList = pageConfig.config.menuConfigEmployeeList;
		this.authorizedList = pageConfig.config.menuConfigAuthorizedList;
		this.employeeSMEList = pageConfig.config.menuConfigEmployeeSMEList;
		this.selectedType = this.accountTypeList[0].value;
		this.refreshMenu(true);
		this.typeChanged(this.selectedType);
	}

	typeChanged(selectedType) {
		this.selectedType = selectedType;
		const typesList = pageConfig.config.dropDownTypes;
		switch (selectedType.toLowerCase()) {
			case typesList.all.toLowerCase():
				this.filteredServiceList = [{ text: 'All', value: 'all' }];
				break;
			case typesList.consumer.toLowerCase():
				this.filteredServiceList = this.consumerList;
				break;
			case typesList.employee.toLowerCase():
				this.filteredServiceList = this.employeeList;
				break;
			case typesList.authorized.toLowerCase():
				this.filteredServiceList = this.authorizedList;
				break;
			case typesList.employeeSME.toLowerCase():
				this.filteredServiceList = this.employeeSMEList;
				break;
		}
		this.selectedService = this.filteredServiceList.length > 0 ? this.filteredServiceList[0].value : '';
		this.serviceChanged(this.selectedService);
	}

	serviceChanged(selectedService: string) {
		this.selectedService = selectedService;
		if (selectedService.toLowerCase() !== pageConfig.config.dropDownTypes.all.toLowerCase()) {
			this.sidemenuConfigService.isFiltered = this.selectedService !== '';
			this.isEdit = this.currentItem !== null;
			this.refreshMenu();
		} else {
			this.isEdit = false;
			this.sidemenuConfigService.isFiltered = false;
			this.refreshMenu();
		}
	}
	radioClick(item: ISideMenuItem, cameFromFunction = false) {
		/** item has null at first time, so must be checked firstly */
		if (item) {
			if (item === this.currentItem && !cameFromFunction) {
				this.currentItem = null;
				this.isEdit = false;
			} else {
				this.currentItem = item;
				this.isEdit = this.selectedService !== pageConfig.config.dropDownTypes.all.toLowerCase();
				this.left = this.right = false;
				if (item['parentId']) {
					this.left = true;
				} else {
					const arr = this.getItemArray(item);
					const indexOfParent = arr.indexOf(item);

					/** enable right button if and only if the parent isn't first parent */
					this.right = !(!(indexOfParent > 0) || (arr[indexOfParent + 1] && arr[indexOfParent + 1]['parentId']));
				}
			}
		}
	}
	private getItemArray(item: ISideMenuItem) {
		let arr: ISideMenuItem[];
		if (item) {
			switch (item.category.toLowerCase()) {
				case 'account':
					arr = this.accountItems;
					break;
				case 'service':
					arr = this.serviceItems;
					break;
				case 'general':
					arr = this.generalItems;
					break;
			}
		}
		return arr;
	}
	addNew() {
		this.notification.show();
	}
	editItem(item) {
		this.notification.show(true, item);
	}
	deleteItem(item) {
		const notification = new Notification();
		const modal = this;
		this.translateService.get('home.messages').subscribe((data) => {
			notification.bodyContent = data['delete-text'];
			notification.primaryButtonText = data['buttons']['cancel'];
			notification.secondaryButtonText = data['buttons']['sure'];
		});
		notification.primaryButtonStyle = 'btn registration';
		notification.primaryButtonClick = function () {
			modal.notificationService.notificationModal.hide();
		};
		notification.secondaryButtonClick = function () {
			modal.sidemenuConfigService.deleteItem(item).subscribe(
				() => {
					if (modal.selectedService.toLowerCase() !== pageConfig.config.dropDownTypes.all.toLowerCase()) {
					} else {
						modal.isEdit = false;
						modal.sidemenuConfigService.isFiltered = false;
					}
					modal.refreshMenu(true);
				},
				(error) => {
					modal.sidemenuConfigService.createError('menu-item-deleting-error');
				}
			);
			modal.notificationService.notificationModal.hide();
		};
		this.notificationService.createNotification(notification);
	}
	swapWithNextItem() {
		this.sidemenuConfigService.isFiltered = this.selectedService !== '';
		this.sidemenuConfigService.swapWithNextItem(this.currentItem).subscribe(
			() => {
				this.refreshMenu(true);
			},
			(error) => {
				this.sidemenuConfigService.createError('menu-odering-error');
				this.refreshMenu();
			}
		);
	}
	swapWithPreviousItem() {
		this.sidemenuConfigService.isFiltered = this.selectedService !== '';
		this.sidemenuConfigService.swapWithPreviousItem(this.currentItem).subscribe(
			() => {
				this.refreshMenu(true);
			},
			(error) => {
				this.sidemenuConfigService.createError('menu-odering-error');
				this.refreshMenu(true);
			}
		);
	}
	makeItemChild() {
		this.sidemenuConfigService.isFiltered = this.selectedService !== '';
		this.sidemenuConfigService.makeItemChild(this.currentItem).subscribe(
			() => {
				this.refreshMenu(true);
			},
			(error) => {
				this.sidemenuConfigService.createError('error-text');
			}
		);
	}
	makeItemParent() {
		this.sidemenuConfigService.isFiltered = this.selectedService !== '';
		this.sidemenuConfigService.makeItemParent(this.currentItem).subscribe(
			() => {
				this.refreshMenu(true);
			},
			(error) => {
				this.sidemenuConfigService.createError('error-text');
			}
		);
	}
	/** this flag tell us that call API or not? */
	public refreshMenu(callingAPI = false) {
		if (callingAPI) {
			this.sidemenuConfigService.loadMenuItems().subscribe((menu: MenuItemsGroup) => {
				this.filterItems();
			});
		} else {
			this.filterItems();
		}
	}
	private filterItems() {
		if (this.selectedService !== '') {
			this.sidemenuConfigService.filterByAccountAndService(this.selectedType, this.selectedService);
		}
		this.accountItems = this.sidemenuConfigService.menuItemsGroups.AccountItems;
		this.serviceItems = this.sidemenuConfigService.menuItemsGroups.ServiceItems;
		this.generalItems = this.sidemenuConfigService.menuItemsGroups.GeneralItems;

		const arr = this.getItemArray(this.currentItem);
		if (arr) {
			this.currentItem = arr.length > 0 ? this.currentItem : null;
			const indexOfNewItem = arr.indexOf(arr.find((x) => x.id === this.currentItem.id));
			if (indexOfNewItem > -1) {
				this.radioClick(arr[indexOfNewItem], true);
			} else {
				this.isEdit = false;
			}
		} else {
			this.currentItem = null;
		}
	}
}
