import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
	selector: 'sp-toggle-button',
	templateUrl: './sp-toggle-button.component.html',
	styleUrls: ['./sp-toggle-button.component.css'],
})
export class SpToggleButtonComponent {
	/**event fired when click on check box */
	@Output()
	option: EventEmitter<boolean> = new EventEmitter();
	/**label */
	@Input()
	label = '';
	/**id for checkbox */
	@Input()
	id = '';
	/**name for checkbox */
	@Input()
	name;
	/**flag show if check box checked or not */
	@Input()
	check: boolean;
	/**empty constructor */
	/** to show tooltip with this value on hover on toggle */
	@Input() toggleTooltip: string;
	constructor() {}

	/**
	 * toggles the check that represents the toggle button current value
	 * then emits the value
	 */
	toggle() {
		this.check = !this.check;
		this.option.emit(this.check);
	}
}
