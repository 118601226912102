import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponseBase, HttpResponse } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';

@Injectable()
export class PaymentCsvService {
	constructor(private http: HttpClient) {}

	exportToCSV(type: string, startDate: string, endDate: string) {
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/x-ms-excel');
		// DON"T REFACTOR!!
		// This is handled this way to solve browser's problem with un-trusted events
		const downloadWindow = window.open('', '_blank');
		const apiPath = `${API_URLS.AnyDownload.BASE_PATH}${API_URLS.AnyDownload.ExportPaymentInCSV.DOWNLOAD_CSV}`
			.replace('{0}', type)
			.replace('{1}', startDate)
			.replace('{2}', endDate);

		this.http
			.get(apiPath, {
				headers: headers,
				responseType: 'blob' as 'json',
				observe: 'response',
			})
			.subscribe((response: HttpResponse<Blob>) => {
				const header = response.headers;
				const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
				const blob = new Blob([response.body], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const objectUrl = URL.createObjectURL(blob);
				const contentHeader = response.headers.get('Content-Disposition');
				const fileName = contentHeader
					? contentHeader.split('filename=')[1].replace(/['"]+/g, '')
					: 'Payment-Export.csv';
				a.href = objectUrl;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
			});
	}
}
