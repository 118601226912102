export class HeaderVariables {
	public loggedIn = false;
	public leftText = 'Administrador Login';
	public welcomeText: string;

	constructor(loggedIn = false, leftText: string = 'Administrador Login', welcomeText: string = '') {
		this.loggedIn = loggedIn;
		this.leftText = leftText;
		this.welcomeText = welcomeText;
	}
}
