import { UserProfile } from './../../models/user-profile.model';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { UserRoles } from '../enums/user-roles.enum';
import { GroupNames } from '../enums/group-names.enum';
import { JWTResponse } from '../../models/jwt-response.model';

@Injectable()
export class JWTHelper {
	constructor(private storageService: StorageService) {}

	/**
	 * Get UserProfile from the jwt recieved from the server
	 * @param token the base64 token jwt recieved
	 */
	getUserProfileFromJWT(token: string): UserProfile {
		const parts = token.split('.');
		// ensure the jwt is correct
		if (parts.length !== 3) {
			throw new Error('JWT must have 3 parts');
		}

		// convert jwt into string
		const decoded = this.convertFromBase64ToString(parts[1]);
		const userprofileResponse: JWTResponse = JSON.parse(decoded);
		const userProfile = new Object() as UserProfile;
		userProfile.name = userprofileResponse.userProfile?.name || null;
		userProfile.isAdmin = userprofileResponse.userProfile?.isAdmin || false;
		userProfile.groups = userprofileResponse.groups || [];
		userProfile.roles = userprofileResponse.roles || [];

		// incase group is empty and user has admin role
		if (
			userProfile.groups.length === 0 &&
			userProfile.roles.find((role) => {
				return role.toLowerCase() === UserRoles.Admin.toLowerCase();
			})
		) {
			userProfile.groups.push(GroupNames.AdministradorToken);
		}
		return userProfile;
	}

	/**
	 * Converts the encoded string into decoded string
	 * @param base64 string encoded in base64
	 */
	public convertFromBase64ToString(base64: string) {
		if (atob) {
			try {
				return atob(base64);
			} catch (e) {
				throw new Error('The string to be decoded is not correctly encoded.');
			}
		} else {
			throw new Error('browser decoding not supported');
		}
	}

	/**
	 * Compare refresh token against current date
	 * @return boolean indicates the referesh token is finished or not
	 */
	public isRefereshTokenExpired(): boolean {
		return new Date().getTime() - this.getRefreshTokenExpirationDate().getTime() > 1;
	}

	public isAccessTokenExpired(): boolean {
		return new Date().getTime() - this.getAccessTokenExpirationDate().getTime() > 1;
	}
	/**
	 * Get expiring time of refresh token
	 */
	private getRefreshTokenExpirationDate(): Date {
		const refDate = this.storageService.getStorage('refreshTokenExpirationTime');
		return this.storageService.refreshExpirationTime || new Date(refDate);
	}
	private getAccessTokenExpirationDate(): Date {
		const accessDate = this.storageService.getStorage('accessTokenExpirationTime');
		return this.storageService.accessExpirationTime || new Date(accessDate);
	}
}
