import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../../common/constants/routes-config';
import { Observable } from 'rxjs';

@Injectable()
export class SchamanService {
	constructor(private http: HttpClient) {}

	getConfig(): Observable<object> {
		return this.http.get(API_URLS.SCHAMAN.GET);
	}

	sendConfig(objectToSend: any): Observable<object> {
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append('Accept', 'application/json');
		const options: { headers: HttpHeaders } = {
			headers: headers,
		};
		return this.http.patch(API_URLS.SCHAMAN.PATCH, objectToSend, options);
	}
}
