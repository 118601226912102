import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from '../../../config/pages-config';

@Injectable()
export class ReloadGuard implements CanActivate, CanActivateChild {
	constructor(private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		let currentRoute = window.location.href;
		if (this.router && this.router.url !== '/') {
			currentRoute = this.router.url;
		}
		if (currentRoute.indexOf(state.url) !== -1) {
			this.router.navigate([config.login.route], {});
			return false;
		}
		return true;
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.canActivate(childRoute, state);
	}
}
