import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IDropdown } from '../../common/components/sp-dropdown/IDropdown.interface';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigurationService } from '../../common/services/configuration.service';
import { GDPRConfig } from '../../models/gdbr-config.model';
import { NotificationService } from '../../common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../../models/notification.model';

@Component({
	selector: 'sp-gdpr-propreties',
	templateUrl: './gdpr-propreties.component.html',
	styleUrls: ['./gdpr-propreties.component.scss'],
})
export class GdprPropretiesComponent implements OnInit {
	userTypes: IDropdown[];
	gdbrForm: FormGroup;
	selectedUserType: string;
	gdprConfiguration: GDPRConfig[];
	gdprConfigurationItem: GDPRConfig;
	constructor(
		private formBuilder: FormBuilder,
		private configurationService: ConfigurationService,
		private notificationService: NotificationService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.userTypes = new Array();
		// initialize with first item
		this.gdprConfiguration = this.configurationService.getGDPRConfigurations();
		this.gdprConfiguration.map((el) => {
			this.userTypes.push({ text: el.userType, value: el.userType });
		});
		this.selectedUserType = this.userTypes[0].value.toLowerCase();

		this.gdprConfigurationItem = this.gdprConfiguration.find((el) => el.userType === this.selectedUserType);
		this.gdbrForm = this.formBuilder.group({
			userType: new FormControl(this.selectedUserType),
			startDate: new FormControl(this.gdprConfigurationItem.startDate.substring(0, 10)),
			endDate: new FormControl(this.gdprConfigurationItem.endDate.substring(0, 10)),
			Frequency: new FormControl(+this.gdprConfigurationItem.frequency),
		});
	}

	addGDPR() {
		if (+new Date(this.gdbrForm.value['startDate']) > +new Date(this.gdbrForm.value['endDate'])) {
			const notification = new Notification();
			notification.bodyContent = 'Please enter a valid expiration date';
			notification.primaryButtonText = 'cancel';
			notification.primaryButtonClick = () => {
				this.notificationService.notificationModal.hide();
			};
			this.notificationService.createNotification(notification);
		} else {
			const changed = this.updateUserType(this.selectedUserType);
			const body = {
				gdprConfigurations: this.gdprConfiguration,
			};

			this.configurationService.patchConfiguration(body).subscribe(
				(res) => {
					this.gdprConfigurationItem.startDate = changed.startDate;
					this.gdprConfigurationItem.endDate = changed.endDate;
					this.gdprConfigurationItem.frequency = changed.frequency;

					const notification = new Notification();
					this.translateService.get('home.savedItem').subscribe((data) => {
						notification.bodyContent = data['save-text'];
						notification.primaryButtonText = data.buttons.cancel;
					});
					notification.primaryButtonClick = () => {
						this.notificationService.notificationModal.hide();
					};
					this.notificationService.createNotification(notification);
				},
				(err) => {
					const notification = new Notification();
					notification.bodyContent = 'Something Went Wrong';
					notification.primaryButtonText = 'cancel';
					notification.primaryButtonClick = () => {
						this.notificationService.notificationModal.hide();
					};
					this.notificationService.createNotification(notification);
				}
			);
		}
	}

	dateToISO(keyName: string) {
		return new Date(this.gdbrForm.value[keyName]).toISOString().split('.')[0];
	}

	selectedValueChanged(value) {
		this.updateUserType(this.selectedUserType);
		this.gdprConfigurationItem = this.gdprConfiguration.find((el) => el.userType === value);
		this.gdbrForm.controls['Frequency'].setValue(+this.gdprConfigurationItem.frequency);
		this.gdbrForm.controls['startDate'].setValue(this.gdprConfigurationItem.startDate.substring(0, 10));
		this.gdbrForm.controls['endDate'].setValue(this.gdprConfigurationItem.endDate.substring(0, 10));
		this.selectedUserType = value;
	}

	updateUserType(userType: string) {
		const changed = this.gdprConfiguration.find((el) => el.userType === userType);
		changed.startDate = this.dateToISO('startDate');
		changed.endDate = this.dateToISO('endDate');
		changed.frequency = this.gdbrForm.value['Frequency'];
		return changed;
	}
}
