<div class="form-group check-box text-align-large display-flex" [ngClass]="{ 'check-box-disabled': isDisabled }">
	<div class="col-1">
		<input
			[id]="name"
			class="col-1"
			[disabled]="isDisabled"
			type="checkbox"
			[checked]="check"
			[name]="name"
			(click)="toggle()"
		/>
		<label [for]="name"></label>
	</div>
	<span class="col-11">{{ label }}</span>
</div>
